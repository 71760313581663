import { IsMassAccount, IsVaAccount } from 'modules';
import { MASS_CHIPS, BASE_CHIPS, VA_CHIPS } from './constants';

const getFilterMappingObj = (filterType) => {
  const isMassAccount = IsMassAccount();
  const isVaAccount = IsVaAccount();

  if (isMassAccount) return { ...BASE_CHIPS, ...MASS_CHIPS(filterType) };
  if (isVaAccount) return { ...BASE_CHIPS, ...VA_CHIPS() };

  return { ...BASE_CHIPS };
};

export default getFilterMappingObj;
