/* eslint-disable react/prop-types */
import React, { memo, useMemo, useState } from 'react';

import { TableWrapper } from 'ui-kit';
import useSortTable from 'utils/old/useSortTable';
import getToastLock from 'utils/old/getAddToastLock';
import useToastContext from 'utils/old/useToastContext';
import { URLS } from '_constants';
import { useIsDataCollectorCreator } from 'hooks';
import { COLUMNS, INITIAL_COLUMNS } from './constants';

const VisitsTable = ({ data, sortHandler, sortOption, getInitColumns, visitType }) => {
  const handleSort = useSortTable(sortOption, sortHandler);
  const [activeColumns, setActiveColumns] = useState(INITIAL_COLUMNS);
  const [isOpen, setIsOpen] = useState(false);
  const addToast = useToastContext();

  const [isDataCollectorCreator] = useIsDataCollectorCreator();

  const getToast = () => getToastLock('Enforcement', addToast);

  const dumpedActiveColumns = useMemo(
    () => activeColumns.reduce((acc, value) => ({ ...acc, [value]: value }), {}),
    [activeColumns],
  );

  const tableControlParams = useMemo(
    () => ({
      activeColumns,
      columns: COLUMNS,
      setActiveColumns,
      setIsOpen,
      isOpen,
    }),
    [activeColumns, isOpen],
  );

  const editCellParams = useMemo(
    () => ({
      editText: visitType.toLowerCase(),
      route: URLS.enforcementPages.edit,
      getToast,
      isDataCollectorCreator,
    }),
    [],
  );

  const wrappedColumns = useMemo(
    () =>
      getInitColumns({
        handleSort,
        sortOption,
        dumpedActiveColumns,
        tableControlParams,
        editCellParams,
        getToast, // eslint-disable-next-line
      }),
    [dumpedActiveColumns, sortOption, tableControlParams, handleSort],
  );

  return (
    <TableWrapper
      columns={wrappedColumns}
      data={data || []}
      tableControlParams={tableControlParams}
      isEdit
      editCellParams={editCellParams}
    />
  );
};

export default memo(VisitsTable);
