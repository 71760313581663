export const GET_RETAILER_CITY = 'GET_RETAILER_CITY';
export const GET_RETAILER_CITY_SUCCESS = 'GET_RETAILER_CITY_SUCCESS';
export const GET_RETAILER_CITY_FAIL = 'GET_RETAILER_CITY_FAIL';

export const ADD_RETAILER = 'ADD_RETAILER';
export const ADD_RETAILER_SUCCESS = 'ADD_RETAILER_SUCCESS';
export const ADD_RETAILER_FAIL = 'ADD_RETAILER_FAIL';

export const GET_RETAILER = 'GET_RETAILER';
export const GET_RETAILER_SUCCESS = 'GET_RETAILER_SUCCESS';
export const GET_RETAILER_FAIL = 'GET_RETAILER_FAIL';

export const EDIT_RETAILER = 'EDIT_RETAILER';
export const EDIT_RETAILER_SUCCESS = 'EDIT_RETAILER_SUCCESS';
export const EDIT_RETAILER_FAIL = 'EDIT_RETAILER_FAIL';

export const CHECK_RETAILER_LOCATION = 'CHECK_RETAILER_LOCATION';
export const CHECK_RETAILER_LOCATION_SUCCESS = 'CHECK_RETAILER_LOCATION_SUCCESS';
export const CHECK_RETAILER_LOCATION_FAIL = 'CHECK_RETAILER_LOCATION_FAIL';

export const RESET_RETAILER_DATA = 'RESET_RETAILER_DATA';
