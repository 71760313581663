const COLUMNS = [
  {
    label: 'Name',
    value: 'name',
    disabled: true,
  },
  {
    label: 'Address',
    value: 'address',
    disabled: true,
  },
  {
    label: 'City',
    value: 'city',
    disabled: true,
  },
  {
    label: 'Zip code',
    value: 'zipcode',
    disabled: true,
  },
  {
    label: 'County',
    value: 'county_name',
  },
  {
    label: 'Phone',
    value: 'phone',
  },
  {
    label: 'Retailer Type',
    value: 'cdph_business_type',
  },
  {
    label: 'Age Restriction',
    value: 'age_restricted_flag',
  },
  {
    label: 'Closed Date',
    value: 'closed_date',
  },
  {
    label: 'Sells Tobacco',
    value: 'tobacco',
  },
  {
    label: 'Has Pharmacy Counter',
    value: 'pharmacy',
  },
];

const INITIAL_COLUMNS = ['visitDate', 'agency', 'retailer__name', 'address', 'city', 'zipcode'];

export { COLUMNS, INITIAL_COLUMNS };
