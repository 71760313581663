import React, { useReducer, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { HasAccess, PageContainer } from 'modules';
import BackPageButton from 'modules/BackPageButton';
import { useDispatch, useSelector } from 'react-redux';
import { SurveyEntity, CampaignEntity } from '_entities';
import { Button, Input, BaseSelect, RadioButtonGroup, Section, CheckedList, Checkbox } from 'ui-kit';
import { notification } from 'utils/services';
import { generateQSParams } from 'utils/custom';
import { BUTTON_TYPES, URLS, MESSAGES, PERMISSIONS } from '_constants';
import {
  formNames,
  radioButtonDefaultValues,
  checkboxDefaultValues,
  formInitialState,
  FORM_ACTION_TYPES,
  retailerTypeOptions,
  FORM_REQUIRED_FIELDS,
  FORM_REQUIRED_FIELDS_FULL_LIST,
  PAGE_COLUMNS,
} from './constants';
import { formReducer, init, getRadioValue } from './_utils';
import * as Styled from './styles';

const { getSurveyFormsData } = SurveyEntity.actions;
const { getSurveyForms } = SurveyEntity.selectors;
const { getRetailerCount, addCampaign, getMySecGeogsList } = CampaignEntity.actions;

const { getRetailerCountSelector, getMySecGeogs } = CampaignEntity.selectors;

const AddCampaign = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const retailerCount = useSelector(getRetailerCountSelector);
  const listOfForms = useSelector(getSurveyForms);
  const [submitButtonStatus, setSubmitButtonStatus] = useState(false);
  const formsOptions = useMemo(
    () =>
      listOfForms?.map((elem) => ({
        id: elem.id,
        value: elem.attributes.name,
        label: elem.attributes.name,
        key: elem.id,
        name: formNames.forms,
      })),
    [listOfForms],
  );
  const secGeogsOptions = useSelector(getMySecGeogs);
  const formattedSecGeogsOptions = useMemo(
    () =>
      secGeogsOptions?.map((item) => {
        return {
          id: item.id,
          value: item.id,
          label: item.attributes.name,
          key: formNames.secGeogs,
          name: formNames.secGeogs,
        };
      }),
    [secGeogsOptions],
  );

  const [state, formDispatch] = useReducer(formReducer, formInitialState, init);
  const formStateData = state.data.attributes;
  const formStateErrors = state.errors;

  useEffect(() => {
    (async () => {
      try {
        await Promise.all([dispatch(getSurveyFormsData()), dispatch(getMySecGeogsList())]);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const getValidArrayForBackEnd = (item) => {
    switch (item) {
      case 't':
        return 1;
      case 'f':
        return 0;

      default:
        return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const campaignData = Object.keys(formStateData).reduce((acc, item) => {
      switch (item) {
        case formNames.type: {
          if (isEmpty(formStateData[item].value)) {
            acc[item] = formStateData[item].value || null;
          }
          return acc;
        }

        case formNames.sellTobacco:
        case formNames.sellAlcohol: {
          const validItems = formStateData[item].map((elem) => getValidArrayForBackEnd(elem));
          acc[item] = validItems;
          return acc;
        }

        default: {
          acc[item] = formStateData[item];
          return acc;
        }
      }
    }, {});

    if (!formStateData.includeRetailer) {
      FORM_REQUIRED_FIELDS_FULL_LIST.forEach((name) => {
        formDispatch({
          type: FORM_ACTION_TYPES.VALIDATE_DATA,
          name,
          payload: formStateData[name],
        });
      });
    } else {
      FORM_REQUIRED_FIELDS.forEach((name) => {
        formDispatch({
          type: FORM_ACTION_TYPES.VALIDATE_DATA,
          name,
          payload: formStateData[name],
        });
      });
    }

    if (!state.canBeSubmitted) return;
    setSubmitButtonStatus(true);
    const addCampaingData = {
      data: {
        ...state.data,
        attributes: {
          tobacco: campaignData?.tobacco,
          alcohol: campaignData?.alcohol,
          campaign_name: campaignData?.campaign_name,
          oversampling_percentage: +campaignData?.oversampling_percentage,
          include_all_retailers: campaignData?.include_all_retailers,
          sample_size: +campaignData?.sample_size,
          store_type: campaignData?.store_type,
          private: campaignData?.private,
        },
        relationships: {
          form: { data: listOfForms.filter((item) => item.id === campaignData?.forms.id)[0] },
          sec_geog: { data: secGeogsOptions.filter((item) => item.id === campaignData?.sec_geogs?.id)[0] },
        },
      },
    };

    try {
      const {
        data: { id },
      } = await dispatch(addCampaign(addCampaingData));
      notification.success(MESSAGES.CAMPAIGN.CREATE.SUCCESS);
      history.push(`${URLS.campaign}/${id}`);
    } catch (err) {
      notification.error(err.response?.data?.errors[0].detail);
    }
  };

  const handleChange = useCallback(({ target: { name, value } }) => {
    formDispatch({ type: FORM_ACTION_TYPES.ENTER_DATA, name, payload: value });
    if (name === formNames.includeRetailer && value) {
      formDispatch({ type: FORM_ACTION_TYPES.SET_ALL_OPTIONS });
    }
  }, []);

  const validArrayOfTypes = useMemo(
    () => formStateData?.retailer_types?.map((item) => item.value),
    [formStateData.retailer_types],
  );

  useEffect(() => {
    const params = generateQSParams({
      sec_geogs: formStateData.sec_geogs?.id,
      tobacco: formStateData.tobacco,
      alcohol: formStateData.alcohol,
      store_type: validArrayOfTypes,
    });

    dispatch(getRetailerCount(params));
  }, [formStateData.sec_geogs, formStateData.tobacco, formStateData.alcohol, formStateData.retailer_types]);

  const handleSelectChange = useCallback((obj, name) => {
    formDispatch({ type: FORM_ACTION_TYPES.ENTER_DATA, name: obj?.name || name, payload: obj });
  }, []);

  const handleMultiSelectChange = useCallback((obj) => {
    formDispatch({
      type: FORM_ACTION_TYPES.ENTER_DATA,
      name: formNames.type,
      payload: obj,
    });
  }, []);

  const handleChangeCheckbox = useCallback((obj) => {
    if (obj.target) {
      formDispatch({ type: FORM_ACTION_TYPES.ENTER_DATA, name: obj.target.name, payload: obj.target.value });
    } else {
      formDispatch({ type: FORM_ACTION_TYPES.ENTER_DATA, name: obj.name, payload: obj.values });
    }
  }, []);

  const pageTitle = 'Add campaign';
  const submitButtonTitle = 'Confirm';

  const getErrorForSampeSize = () => {
    if (formStateData[formNames.sampleSize] <= 0) return formStateErrors[formNames.sampleSize];
    if (formStateData[formNames.sampleSize] > retailerCount)
      return 'The sample size should be less than the total number of available retailers ';
    return '';
  };

  const canAddPrivateCampaign = HasAccess(PERMISSIONS.ADD_PRIVATE_CAMPAIGN_FLAG);

  return (
    <PageContainer>
      <Styled.MainContainer>
        <Styled.TopLine>
          <Styled.TitleWrapper>
            <BackPageButton />
            <Styled.Title>{pageTitle}</Styled.Title>
            <Styled.Info>* - required fields</Styled.Info>
          </Styled.TitleWrapper>
          <Styled.ButtonWrapper>
            <Button
              onClick={handleSubmit}
              disabled={submitButtonStatus}
              variant={BUTTON_TYPES.DANGER}
              text={submitButtonTitle}
            />
          </Styled.ButtonWrapper>
        </Styled.TopLine>
        <Styled.Inner>
          <Section title="Campaign information">
            <Styled.InputWrapper>
              <Input
                name={formNames.campaignName}
                title="Campaign name"
                onChange={handleChange}
                error={formStateErrors[formNames.campaignName]}
                value={formStateData[formNames.campaignName]}
                required
                floatingLabel
                pageColumns={PAGE_COLUMNS}
              />
            </Styled.InputWrapper>
            {canAddPrivateCampaign && (
              <Styled.CheckboxWrapper>
                <Styled.CheckboxTitle>Private Campaign</Styled.CheckboxTitle>
                <Checkbox
                  value={state?.data?.attributes?.private}
                  name={formNames.privateCampaign}
                  onChange={handleChangeCheckbox}
                  pageColumns={PAGE_COLUMNS}
                />
              </Styled.CheckboxWrapper>
            )}
            <Styled.InputWrapper>
              <BaseSelect
                name={formNames.secGeogs}
                labelText="Secondary geography"
                options={formattedSecGeogsOptions}
                value={formStateData[formNames.secGeogs]}
                onChange={(value) => handleSelectChange(value, formNames.secGeogs)}
                inputId={formNames.secGeogs}
                placeholder="Select an option"
                error={formStateErrors[formNames.secGeogs]}
                isClearable
                className={formStateErrors[formNames.secGeogs] && 'error'}
                pageColumns={PAGE_COLUMNS}
              />
            </Styled.InputWrapper>
            <Styled.InputWrapper>
              <BaseSelect
                name={formNames.forms}
                labelText="Survey forms*"
                options={formsOptions}
                value={formStateData[formNames.forms]}
                onChange={handleSelectChange}
                inputId={formNames.forms}
                placeholder="Select an option"
                error={formStateErrors[formNames.forms]}
                className={formStateErrors[formNames.forms] && 'error'}
                pageColumns={PAGE_COLUMNS}
              />
            </Styled.InputWrapper>
          </Section>
          <Section title="Retailers to Survey">
            <Styled.RetailerCountContainer isBorder={!formStateData[formNames.includeRetailer]}>
              <Styled.InputWrapper>
                <Styled.Counter>{retailerCount}</Styled.Counter> retailers match this campaign's parameters.
              </Styled.InputWrapper>
              <Styled.InputWrapper>
                <RadioButtonGroup
                  name={formNames.includeRetailer}
                  title="Do you want to include all retailers in the secondary geography?"
                  options={radioButtonDefaultValues}
                  direction="row"
                  value={getRadioValue(formStateData[formNames.includeRetailer])}
                  onChange={handleChange}
                />
              </Styled.InputWrapper>
            </Styled.RetailerCountContainer>
            <Styled.RetailerAdditionalInfo isShow={formStateData[formNames.includeRetailer]}>
              <Styled.InputWrapper>
                Please select retailer characteristics, enter sample size and oversampling percentage
              </Styled.InputWrapper>
              <Styled.InputWrapper>
                <CheckedList
                  title="Sells tobacco"
                  value={formStateData[formNames.sellTobacco]}
                  onChange={handleChangeCheckbox}
                  name={formNames.sellTobacco}
                  options={checkboxDefaultValues}
                  direction="row"
                  pageColumns={PAGE_COLUMNS}
                />
              </Styled.InputWrapper>
              <Styled.InputWrapper>
                <CheckedList
                  title="Sells alcohol"
                  value={formStateData[formNames.sellAlcohol]}
                  onChange={handleChangeCheckbox}
                  name={formNames.sellAlcohol}
                  options={checkboxDefaultValues}
                  direction="row"
                  pageColumns={PAGE_COLUMNS}
                />
              </Styled.InputWrapper>
              <Styled.InputWrapper>
                <BaseSelect
                  name={formNames.type}
                  labelText="Store type"
                  options={retailerTypeOptions}
                  value={formStateData[formNames.type]}
                  onChange={(value) => {
                    handleMultiSelectChange(value);
                  }}
                  inputId={formNames.type}
                  placeholder="Select an option"
                  isMulti
                  pageColumns={PAGE_COLUMNS}
                />
              </Styled.InputWrapper>
              <Styled.InputWrapper>
                <Input
                  name={formNames.sampleSize}
                  title="Sample size"
                  onChange={handleChange}
                  error={getErrorForSampeSize()}
                  value={formStateData[formNames.sampleSize]}
                  required
                  floatingLabel
                  pageColumns={PAGE_COLUMNS}
                />
                <Styled.InputSubText isError={getErrorForSampeSize()}>
                  Sample Size can't be greater than the total number of available retailers that fit the search
                  criteria.
                </Styled.InputSubText>
              </Styled.InputWrapper>
              <Styled.InputWrapper>
                <Input
                  name={formNames.overSamplingPercentage}
                  title="Over-sampling percentage"
                  onChange={handleChange}
                  error={formStateErrors[formNames.overSamplingPercentage]}
                  value={formStateData[formNames.overSamplingPercentage]}
                  required
                  floatingLabel
                  pageColumns={PAGE_COLUMNS}
                />
                <Styled.InputSubText isError={formStateErrors[formNames.overSamplingPercentage]}>
                  Please enter an integer between 0 and 100.
                </Styled.InputSubText>
              </Styled.InputWrapper>
            </Styled.RetailerAdditionalInfo>
          </Section>
        </Styled.Inner>
      </Styled.MainContainer>
    </PageContainer>
  );
};

export default AddCampaign;
