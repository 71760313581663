import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PageContainer, AccessControl } from 'modules';
import { Button } from 'ui-kit';
import { AuthEntity } from '_entities';
import { BUTTON_TYPES, URLS, PERMISSIONS } from '_constants';
import { POLICY_TESTER, RETAILER_DENSITY } from 'assets/icons/snapshot';
import { VISITS, USERS } from 'assets/icons';
import isIncludes from 'modules/AccessControl/isIncludes';
import * as Styled from './styles';

const { getProfileData } = AuthEntity.actions;

const { getDashboardLinksAccount, getProfilePermissions } = AuthEntity.selectors;

function Dashboard() {
  const history = useHistory();
  const dispatch = useDispatch();
  const dashboardInfoSections = useSelector(getDashboardLinksAccount);
  const userPermissions = useSelector(getProfilePermissions);

  const handleViewRetailers = () => history.push(URLS.retailers);
  const handleViewStoreMerger = () => history.push(URLS.storeMerger);
  const handleViewRetailerVisits = () => history.push(URLS.assignments);
  const handleViewCampaignList = () => history.push(URLS.campaign_list);
  const handleAddCampaign = () => history.push(URLS.campaignPages.add);
  const handleViewAssessments = () => history.push(URLS.assessments);
  const handleViewEducation = () => history.push(URLS.education);
  const handleViewEnforcement = () => history.push(URLS.enforcement);
  const handleViewUsers = () => history.push(URLS.users);
  const handleAddUser = () => history.push(URLS.userPages.add);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(getProfileData());
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <PageContainer>
      <Styled.Wrapper>
        <Styled.PageTitle>Dashboard</Styled.PageTitle>
        <Styled.TopLine>
          {dashboardInfoSections?.map(({ title, description, link }) => (
            <Styled.Box key={title}>
              <Styled.Title>{title}</Styled.Title>
              <Styled.Description>{description}</Styled.Description>
              {link?.includes('@') ? (
                <Styled.A href={`mailto:${link}`}>{link}</Styled.A>
              ) : (
                <Styled.A href={link}>See more</Styled.A>
              )}
            </Styled.Box>
          ))}
        </Styled.TopLine>
        <Styled.BottomLine>
          <Styled.Box>
            <Styled.IconWrapper>
              <Styled.Icon src={RETAILER_DENSITY} alt="" role="presentation" />
            </Styled.IconWrapper>
            <Styled.Title>Retailers</Styled.Title>
            <Styled.ButtonRow>
              <Button text="View Retailers" variant={BUTTON_TYPES.DARK} onClick={handleViewRetailers} />
              <AccessControl permission={PERMISSIONS.VIEW_SIMILARSTORES}>
                <Button text="View Store Merger" variant={BUTTON_TYPES.DARK} onClick={handleViewStoreMerger} />
              </AccessControl>
            </Styled.ButtonRow>
          </Styled.Box>
          <AccessControl permission={PERMISSIONS.VIEW_ASSIGNMENT}>
            <Styled.Box>
              <Styled.IconWrapper>
                <Styled.Icon src={POLICY_TESTER} alt="" role="presentation" />
              </Styled.IconWrapper>
              <Styled.Title>Collect</Styled.Title>
              <Styled.ButtonRow>
                <Button text="View Assignments" variant={BUTTON_TYPES.DARK} onClick={handleViewRetailerVisits} />
                <Button text="View Campaign List" variant={BUTTON_TYPES.DARK} onClick={handleViewCampaignList} />
                <AccessControl permission={PERMISSIONS.ADD_CAMPAIGN}>
                  <Button text="Add Campaign" variant={BUTTON_TYPES.DANGER} onClick={handleAddCampaign} />
                </AccessControl>
              </Styled.ButtonRow>
            </Styled.Box>
          </AccessControl>
          {isIncludes(
            userPermissions,
            [
              PERMISSIONS.VIEW_VISIT,
              PERMISSIONS.VIEW_ENFORCEMENT_VISITS,
              PERMISSIONS.VIEW_ASSESSMENT_VISITS,
              PERMISSIONS.VIEW_EDUCATION_VISITS,
            ],
            false,
            true,
          ) && (
            <Styled.Box>
              <Styled.IconWrapper>
                <Styled.Icon src={VISITS} alt="" role="presentation" />
              </Styled.IconWrapper>
              <Styled.Title>Visits</Styled.Title>
              <Styled.ButtonRow>
                {isIncludes(userPermissions, [PERMISSIONS.VIEW_VISIT, PERMISSIONS.VIEW_ASSESSMENT_VISITS], true) && (
                  <Button text="View Assessments" variant={BUTTON_TYPES.DARK} onClick={handleViewAssessments} />
                )}
                {isIncludes(userPermissions, [PERMISSIONS.VIEW_VISIT, PERMISSIONS.VIEW_EDUCATION_VISITS], true) && (
                  <Button text="View Education" variant={BUTTON_TYPES.DARK} onClick={handleViewEducation} />
                )}
                {isIncludes(userPermissions, [PERMISSIONS.VIEW_VISIT, PERMISSIONS.VIEW_ENFORCEMENT_VISITS], true) && (
                  <Button text="View Enforcement" variant={BUTTON_TYPES.DARK} onClick={handleViewEnforcement} />
                )}
              </Styled.ButtonRow>
            </Styled.Box>
          )}
          <AccessControl permission={PERMISSIONS.VIEW_USER}>
            <Styled.Box>
              <Styled.IconWrapper>
                <Styled.Icon src={USERS} alt="" role="presentation" />
              </Styled.IconWrapper>
              <Styled.Title>Users</Styled.Title>
              <Styled.ButtonRow>
                <Button text="View Users" variant={BUTTON_TYPES.DARK} onClick={handleViewUsers} />
                <AccessControl permission={PERMISSIONS.ADD_USER}>
                  <Button text="Add User" variant={BUTTON_TYPES.DANGER} onClick={handleAddUser} />
                </AccessControl>
              </Styled.ButtonRow>
            </Styled.Box>
          </AccessControl>
        </Styled.BottomLine>
      </Styled.Wrapper>
    </PageContainer>
  );
}

export default Dashboard;
