import moment from 'moment';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Section, Button } from 'ui-kit';
import { isEmpty } from 'lodash';
import { VisitEntity, CampaignEntity } from '_entities';
import { BUTTON_TYPES, URLS, USER_ROLE_TYPES, PERMISSIONS } from '_constants';
import { getViolationData, getDataFromInclude, pickViolationInfoChecbox } from 'utils/custom';
import { AccessControl, IsMassAccount } from 'modules';
import * as Styled from '../../styles';

const { getVisitData, getVisitInfo } = VisitEntity.selectors;
const { getAssignmentsTableData } = CampaignEntity.selectors;
const { MULTI_ACCOUNT_MANAGER, ACCOUNT_MANAGER, AGENCY_MANAGER, CAMPAIGN_MANAGER, DATA_COLLECTOR } = USER_ROLE_TYPES;

const renderOthersOptions = ({ visitData, visitIncluded, otherFields }) => {
  if (isEmpty(otherFields)) return null;
  return (
    <>
      {otherFields.map((field) => {
        const otherFieldData = getViolationData(visitIncluded, field);
        const fieldValue = visitData?.attributes[field] || visitData?.attributes?.metadata[field] || '';
        if (Array.isArray(fieldValue) && !isEmpty(fieldValue)) {
          const othersViolationsOptions = getViolationData(visitIncluded, field);
          return fieldValue.map((questionKey) => <p key={questionKey}>{othersViolationsOptions[questionKey]}</p>);
        }
        return <p key="field">{otherFieldData?.[fieldValue] || fieldValue}</p>;
      })}
    </>
  );
};

function ViolationView() {
  const history = useHistory();
  const { data: visitData, included: visitIncluded } = useSelector(getVisitData);
  const { editViolationUrl } = useSelector(getVisitInfo);
  const isMassAccount = useSelector(IsMassAccount);
  const assignments = useSelector(getAssignmentsTableData);
  const visitStateAddress = visitIncluded.find((item) => item.type === 'Retailer')?.attributes.state;

  const {
    violation_checkbox: violationCheckbox,
    is_violation_open_status: isOpenViolation,
    violation_closed_on: closedOn,
    violation_created_on: createdOn,
    violation_created_by_id: creatorId,
    violation_info_last_modified_on: modifiedOn,
    violation_info_last_modified_by_id: modifiedId,
    violation_info_last_modified_elements: modifiedElements,
    visit_type_text: visitTypeText,
    aggregationpolygon_set: secGeog,
  } = visitData.attributes && visitData.attributes;

  const roleRuleSet = {
    creator: [DATA_COLLECTOR],
    agency: [AGENCY_MANAGER],
    agencySecGeog: [CAMPAIGN_MANAGER],
    account: [ACCOUNT_MANAGER],
    all: [MULTI_ACCOUNT_MANAGER],
  };

  const permissionData = visitData && {
    ...visitData.relationships,
    assignments: assignments?.usersList || [],
    state: visitStateAddress,
    secGeog,
  };

  const penaltyField = {
    Enforcement: 'action_checkbox_enforcement',
    Education: 'action_checkbox_education',
  };
  const actionCheckbox = visitData.attributes && visitData.attributes[penaltyField[visitTypeText]];
  const { violation_action_field: violationActionField, violation_type_field: violationTypeField } = getDataFromInclude(
    visitIncluded,
    'Form',
  ).attributes;

  const [mainViolationTypeField, ...otherViolationFields] = violationTypeField
    .replace(/ /g, '')
    .split(',')
    .filter((el) => el !== '');

  const [mainViolationActionField, ...otherActionFields] = violationActionField
    .replace(/ /g, '')
    .split(',')
    .filter((el) => el !== '');

  const violationTypeData = getViolationData(visitIncluded, mainViolationTypeField || 'violation_checkbox');
  const violationPenaltyData = getViolationData(visitIncluded, mainViolationActionField || penaltyField[visitTypeText]);

  const pickedViolationCheckbox = pickViolationInfoChecbox(visitData, mainViolationTypeField, violationCheckbox);
  const pickedActionCheckbox = pickViolationInfoChecbox(visitData, mainViolationActionField, actionCheckbox);

  const violationStatus = isOpenViolation ? 'Open' : 'Closed';
  const usersEmail = visitIncluded.reduce((acc, item) => {
    if (item.type !== 'User') return acc;
    acc[item.id] = item.attributes.email;
    return acc;
  }, {});

  const handleEditViolation = useCallback(() => {
    history.push(`${editViolationUrl}/${visitData.id}`);
  });

  return (
    <Styled.ViolationSectionGroup>
      <Section title="Violation status information">
        <Styled.VerticalBorder />
        <Styled.Dl>
          <Styled.ItemWrapper>
            <Styled.Dt>Violation status</Styled.Dt>
            <Styled.Dd>{violationStatus}</Styled.Dd>
            <Styled.HorizontalBorder />
          </Styled.ItemWrapper>
          <Styled.ItemWrapper>
            <Styled.Dt>Violation type</Styled.Dt>
            <Styled.Dd>
              {isEmpty(violationTypeData) || isEmpty(pickedViolationCheckbox) ? (
                <p> - </p>
              ) : (
                <>
                  {pickedViolationCheckbox.map((item, i) => {
                    const textFieldValue = typeof violationTypeData === 'string' ? item : '';
                    if (
                      mainViolationTypeField === 'sale_to_minor' &&
                      violationTypeData[item] === 'Yes' &&
                      visitTypeText === 'Enforcement'
                    ) {
                      return <p key={`${violationTypeData[item]}${i}`}>Sale to a minor</p>;
                    }
                    return <p key={`${violationTypeData[item]}${i}`}>{textFieldValue || violationTypeData[item]}</p>;
                  })}
                  {renderOthersOptions({
                    visitData,
                    visitIncluded,
                    otherFields: otherViolationFields,
                  })}
                </>
              )}
            </Styled.Dd>
            <Styled.HorizontalBorder />
          </Styled.ItemWrapper>
          <Styled.ItemWrapper>
            <Styled.Dt>Penalty</Styled.Dt>
            <Styled.Dd>
              {isEmpty(violationPenaltyData) || isEmpty(pickedActionCheckbox) ? (
                <p> - </p>
              ) : (
                <>
                  {pickedActionCheckbox.map((item, i) => {
                    const textFieldValue = typeof violationPenaltyData === 'string' ? item : '';
                    return <p key={`${item}${i}`}>{textFieldValue || violationPenaltyData[item]}</p>;
                  })}
                  {renderOthersOptions({
                    visitData,
                    visitIncluded,
                    otherFields: otherActionFields,
                  })}
                </>
              )}
            </Styled.Dd>
            {!isOpenViolation && closedOn && <Styled.HorizontalBorder />}
          </Styled.ItemWrapper>
          {!isOpenViolation && closedOn && (
            <Styled.ItemWrapper>
              <Styled.Dt>Violation case closed date</Styled.Dt>
              <Styled.Dd>{moment(closedOn).format('L')}</Styled.Dd>
            </Styled.ItemWrapper>
          )}
          {isMassAccount && (
            <Styled.ViolationButton>
              <AccessControl
                permission={PERMISSIONS.CHANGE_VISIT_VIOLATION_INFO}
                roleRuleSet={roleRuleSet}
                data={permissionData}
              >
                <Button onClick={handleEditViolation} text="Edit violation status" variant={BUTTON_TYPES.DARK} />
              </AccessControl>
            </Styled.ViolationButton>
          )}
        </Styled.Dl>
      </Section>
      <Section title="Violation submission information">
        <Styled.Dl>
          <Styled.SubmissionItemWrapper>
            <Styled.Dt>Created on</Styled.Dt>
            <Styled.Dd>{moment(createdOn).format('L')}</Styled.Dd>
          </Styled.SubmissionItemWrapper>
          <Styled.SubmissionItemWrapper>
            <Styled.Dt>Created by</Styled.Dt>
            <Styled.Dd>
              <Link to={`${URLS.users}/${creatorId}`}>{usersEmail[creatorId]}</Link>
            </Styled.Dd>
          </Styled.SubmissionItemWrapper>
          <Styled.SubmissionItemWrapper>
            <Styled.Dt>Last modified on</Styled.Dt>
            <Styled.Dd>{moment(modifiedOn).format('L')}</Styled.Dd>
          </Styled.SubmissionItemWrapper>
          <Styled.SubmissionItemWrapper>
            <Styled.Dt>Last modified by</Styled.Dt>
            <Styled.Dd>
              <Link to={`${URLS.users}/${modifiedId}`}>{usersEmail[modifiedId]}</Link>
            </Styled.Dd>
          </Styled.SubmissionItemWrapper>
          <Styled.SubmissionItemWrapper>
            <Styled.Dt>Last modified</Styled.Dt>
            <Styled.Dd>{modifiedElements?.trim().slice(0, -1) || ''}</Styled.Dd>
          </Styled.SubmissionItemWrapper>
        </Styled.Dl>
      </Section>
    </Styled.ViolationSectionGroup>
  );
}

export default ViolationView;
