// some setState functions needs for ADA functionality line 92, and line 96
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PageContainer, SurveyModal, AccessControl } from 'modules';
import { isEmpty, isNaN } from 'lodash';
import queryString from 'query-string';
import { isValidDate } from 'utils/old/checkSearchDate';
import { Button } from 'ui-kit/old/styles/button.style';
import { fetchRetailers, retailersCSV } from '_entities/old/Retailers/retailers.service';
import Filter from 'ui-kit/old/filter';
import { notification } from 'utils/services';
import { initialState } from '_entities/old/Filter/filter.reducer';
import qs from 'qs';
import styles from '_constants/old/_constants/index';
import { ENTITY_TYPE } from 'modules/Modals/old/mapPopup/components/constants';
import { FILTER_TABS, FILTER_TYPES } from '_constants/old/filters';
import FILTER_FIELDS from '_constants/old/_constants/filters/FILTER_FIELDS';
import { newSavePageToPdf } from 'utils/custom';
import { FilterModal, MapPopup } from 'modules/Modals';
import { ChipList } from 'modules/Modals/old/FilterModal/components/ChipsList';
import { SetQueryParams } from 'utils/old/setQueryParams';
import sortParam from 'utils/old/sortParam';
import ITEMS_PER_PAGE_OPTIONS from '_constants/old/_constants/ITEMS_PER_PAGE_OPTIONS';
import DEFAULT_SORT from '_constants/old/_constants/DEFAULT_SORT';
import RetailersTable from 'modules/old/RetailersTable';
import { TableTabsWrapper, TableSizeControls, Loader } from 'ui-kit';
import qsSearchApplyUtil from 'utils/old/qsSearchApplyUtil';
import { EXPORT_DATA_OPTIONS_NAMES } from '_constants/old/_constants/EXPORT_DATA';
import ExportDataDropDown from 'ui-kit/old/ExportDataDropDown';
import { SurveyEntity, NewFiltersEntity, FilterEntity } from '_entities';
import { URLS, PERMISSIONS, USER_ROLES, PAGE_TYPES } from '_constants';
import filterMapping from './filters/chips';
import MapView from './mapView';
import * as Styled from './styles';

const { getSurveyFormsData } = SurveyEntity.actions;
const { applyFilters, setShadowFilter } = FilterEntity.actions;
const { getLocationMapping, getVisitsAgency } = NewFiltersEntity.actions;

const {
  BUTTON_STYLES: { RETAILERS },
} = styles;
const FilterData = [
  {
    name: FILTER_TABS.QUICK_QUERY,
    isActive: true,
  },
  {
    name: FILTER_TABS.QUERY_BUILDER,
    isActive: true,
  },
  {
    name: FILTER_TABS.ADVANCED_FILTERS,
    isActive: true,
  },
];

const titles = [
  { value: 'List View', id: 'list' },
  { value: 'Map View', id: 'map' },
];

const MODAL_NAMES = {
  FILTER_MODAL: 'FILTER_MODAL',
};

const { ACCOUNT_MANAGER, MULTI_ACCOUNT_MANAGER, AGENCY_MANAGER, CAMPAIGN_MANAGER, DATA_COLLECTOR } = USER_ROLES;

const Retailers = (props) => {
  const {
    location: { search },
  } = props;

  const [activeTab, setActiveTab] = useState(titles[0].id);
  const [exportValue, setExportValue] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [currentPerPage, setCurrentPerPage] = useState(ITEMS_PER_PAGE_OPTIONS[1].value);
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpenSurveyModal, setOpenSurveyModal] = useState(false);
  const [surveyRetailerId, setSurveyRetailerId] = useState();
  const [sortOption, setSortOption] = useState({
    sortBy: DEFAULT_SORT.retailers.prop,
    sortOrder: DEFAULT_SORT.retailers.order,
  });

  const [loading, setLoading] = useState(false);
  const [isFormLoad, setIsFormLoad] = useState(false);

  const [mapViewSelectedFeature, setMapViewSelectedFeature] = useState(null);
  const [isMapPopup, setOpenMapPopup] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [retailerId, setRetailerId] = useState(null);
  const [scrollY, setScrollY] = useState(0);
  const [buttonObj, setButtonObj] = useState();

  const handleModalType = (type) => setModalType(type);
  const closeModal = () => setModalType(null);

  const onChangeTabs = (titleTab) => {
    const title = titles.find((item) => item.value === titleTab).id;
    setActiveTab(title);
  };

  const {
    oldRetailers: {
      retailersTableData: { pagination, data },
    },
    oldFilter: { generalFilters, shadowFilters },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    (async function getDataForLocation() {
      try {
        setLoading(true);
        await dispatch(getLocationMapping());
        await dispatch(getVisitsAgency());
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const loadRetailers = useCallback(
    async ({
      page = currentPage,
      perPage = currentPerPage,
      sortBy = sortOption.sortBy,
      sortOrder = sortOption.sortOrder,
      filters = generalFilters,
      ...rest
    } = {}) => {
      try {
        setLoading(true);
        await fetchRetailers(
          {
            ...filters,
            ...rest,
            page,
            perPage,
            sortBy,
            sortOrder,
          },
          dispatch,
        );
        SetQueryParams({
          queryString: {
            ...filters,
            ...rest,
            page,
            page_size: perPage,
            ordering: sortParam({ sortBy, sortOrder }),
          },
          history,
        });
      } catch (e) {
        console.log('e', e);
      } finally {
        setLoading(false);
      }
    },
    [currentPage, dispatch, currentPerPage, SetQueryParams, generalFilters, sortOption],
  );

  const sortHandler = useCallback(
    (sortBy, sortOrder) => {
      setSortOption({ sortBy, sortOrder });
      loadRetailers({ sortBy, sortOrder });
    },
    [loadRetailers, setSortOption],
  );

  const handlePageChange = useCallback(
    ({ value }) => {
      if (currentPage === value) return;
      setCurrentPage(value);
      loadRetailers({ page: value });
    },
    [loadRetailers, setCurrentPage],
  );

  const handlePerPageChange = useCallback(
    ({ value }) => {
      if (currentPerPage === value) return;
      setCurrentPerPage(value);
      setCurrentPage(1);
      loadRetailers({ perPage: value, page: 1 });
    },
    [setCurrentPerPage, setCurrentPage, loadRetailers],
  );

  useEffect(() => {
    const { page, page_size: pageSize, search: searchQuery, ...rest } = queryString.parse(location.search);
    const queryPage = isNaN(+page) || +page === 0 ? currentPage : +page;
    const queryPageSize = isNaN(+pageSize) || +pageSize === 0 ? currentPerPage : +pageSize;
    setCurrentPage(queryPage);
    setCurrentPerPage(queryPageSize);
    setSearchValue(searchQuery || '');

    const newFilters = {
      ...(searchQuery ? { search: searchQuery } : ''),
      [FILTER_FIELDS.IS_CURRENT]: ['t'],
      ...rest,
    };
    dispatch(applyFilters({ page, page_size: pageSize, ...newFilters }));
    loadRetailers({ filters: newFilters, page, perPage: pageSize });
  }, []);

  const onMapViewFeatureSelected = (mapFeature) => {
    if (mapFeature) {
      setRetailerId(mapFeature.id);
      setOpenMapPopup(true);
    } else {
      setOpenMapPopup(false);
      setMapViewSelectedFeature(mapFeature);
    }
  };

  useEffect(() => {
    if (
      shadowFilters &&
      !isEmpty(shadowFilters[FILTER_FIELDS?.VIOLATION_COUNT_GRT]) &&
      !isEmpty(shadowFilters[FILTER_FIELDS?.VIOLATION_COUNT_GRT_DATEPICKER_RANGE]) &&
      shadowFilters[FILTER_FIELDS?.VIOLATION_COUNT_GRT_DATEPICKER_RANGE]?.length > 11
    ) {
      dispatch(
        setShadowFilter({
          ...shadowFilters,
          [FILTER_FIELDS?.VIOLATION_COUNT_GRT_DATA_RANGE]: `${
            shadowFilters[FILTER_FIELDS?.VIOLATION_COUNT_GRT_DATEPICKER_RANGE]
          },${shadowFilters[FILTER_FIELDS?.VIOLATION_COUNT_GRT]}`,
        }),
      );
    } else {
      dispatch(
        setShadowFilter({
          ...shadowFilters,
          [FILTER_FIELDS?.VIOLATION_COUNT_GRT_DATA_RANGE]: null,
        }),
      );
    }
  }, [shadowFilters?.violation_count_grt_datepicker_range, shadowFilters?.violation_count_grt]);

  const handleRemoveSearch = () => {
    if (isEmpty(searchValue)) return;
    qsSearchApplyUtil({
      dispatch,
      getApi: loadRetailers,
      generalFilters,
    });
    setSearchValue('');
  };

  const handleOnChangeInputSearch = (searchVal) => {
    if (isEmpty(searchVal)) {
      setSearchValue('');
      qsSearchApplyUtil({
        dispatch,
        getApi: loadRetailers,
        generalFilters,
      });
    }
    setSearchValue(searchVal);
  };

  const handleClickInput = () => {
    if (isEmpty(searchValue)) return;
    const searchString = isValidDate(searchValue);
    qsSearchApplyUtil({
      search: searchString,
      dispatch,
      getApi: loadRetailers,
      generalFilters,
    });
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      const searchString = isValidDate(searchValue);
      qsSearchApplyUtil({
        search: searchString,
        dispatch,
        getApi: loadRetailers,
        generalFilters,
      });
    }
  };

  const closeSurveyModal = () => {
    setOpenSurveyModal(false);
  };

  const getScroll = () => {
    setScrollY(document.body.scrollTop);
  };

  useEffect(() => {
    document.body.addEventListener('scroll', getScroll);
    return () => {
      document.body.removeEventListener('scroll', getScroll);
    };
  }, [document.body.scrollTop]);

  const OpenSurveyModal = (isOpen, id) => {
    setOpenSurveyModal(isOpen);
    setSurveyRetailerId(id);
    (async function getSurveyForms() {
      try {
        setIsFormLoad(true);
        await dispatch(getSurveyFormsData());
      } catch (error) {
        console.log(error);
      } finally {
        setIsFormLoad(false);
      }
    })();
  };

  const handleExportDataChange = ({ value }) => {
    setExportValue(value);
    switch (value) {
      case EXPORT_DATA_OPTIONS_NAMES.EXPORT:
        retailersCSV(dispatch, search, (elem) => notification.success(elem));
        break;
      case EXPORT_DATA_OPTIONS_NAMES.PRINT:
        setLoading(true);
        // In order to show loader before html2canvas function start working we should wrap function in setTimeout
        setTimeout(() => newSavePageToPdf({ setExportLoading: setLoading, hasFilters: true }, PAGE_TYPES.TABLE), 0);
        break;
      default:
    }
  };

  const onKeyDownFilter = (e) => {
    if (e.keyCode === 13) {
      handleModalType(MODAL_NAMES.FILTER_MODAL);
      e.preventDefault();
    }
  };

  const openFilterModal = () => {
    handleModalType(MODAL_NAMES.FILTER_MODAL);
  };

  useEffect(() => {
    const searchObj = qs.parse(search.split('?')[1]);
    dispatch(applyFilters({ ...searchObj, [FILTER_FIELDS.IS_CURRENT]: ['t'] }));
  }, []);

  return (
    <>
      <PageContainer>
        {loading && <Loader />}
        {isMapPopup && (
          <MapPopup
            entityId={retailerId || 221300}
            onClose={setOpenMapPopup}
            setButtonObj={setButtonObj}
            entityType={ENTITY_TYPE.RETAILER}
            allowVisits
          />
        )}
        <Styled.Wrapper>
          <SurveyModal
            onClose={closeSurveyModal}
            open={isOpenSurveyModal && !isFormLoad}
            retailerId={surveyRetailerId}
          />
          <Styled.Header className="table-header">
            <Styled.H1>Retailers</Styled.H1>
            <Styled.HeaderWrapper>
              {activeTab === titles[0].id && (
                <ExportDataDropDown onChange={handleExportDataChange} value={exportValue} />
              )}
              <AccessControl permission={PERMISSIONS.ADD_RETAILER}>
                <Styled.HeaderBox>
                  <Button
                    data-html2canvas-ignore
                    buttonType={RETAILERS}
                    onClick={() => {
                      history.push(URLS.retailersPages.add);
                    }}
                    id="addRetailerButton"
                  >
                    Add Retailer
                  </Button>
                </Styled.HeaderBox>
              </AccessControl>
            </Styled.HeaderWrapper>
          </Styled.Header>
          <Styled.FilterWrapper data-html2canvas-ignore>
            <Filter
              type="button"
              placeholder="Search retailer "
              handleClick={openFilterModal}
              onChangeInput={handleOnChangeInputSearch}
              handleClickInput={handleClickInput}
              onKeyDown={handleKeyDown}
              onKeyDownFilter={onKeyDownFilter}
              onRemoveSearch={handleRemoveSearch}
              searchValue={searchValue}
              isWidth
            />
          </Styled.FilterWrapper>
          <Styled.ChipsWrapper className="table-filters">
            <ChipList
              withoutScroll
              filterMapping={filterMapping}
              getApi={loadRetailers}
              setCurrentPage={setCurrentPage}
            />
          </Styled.ChipsWrapper>
          <TableTabsWrapper
            hasTabs
            onChangeTabs={onChangeTabs}
            activeTab={activeTab}
            tabOptions={titles}
            amount={pagination?.count || 0}
            tableComponent={
              <Styled.TableContainer>
                <RetailersTable
                  data={data}
                  sortOption={sortOption}
                  sortHandler={sortHandler}
                  setOpenSurveyModal={OpenSurveyModal}
                />
                <TableSizeControls
                  itemsPerPageProps={{
                    handlePerPageChange,
                    value: currentPerPage,
                  }}
                  pageCountProps={{
                    value: currentPage,
                    pages: pagination?.pages || 1,
                    handlePageChange,
                  }}
                  paginationInfoProps={{
                    total: pagination?.count || 0,
                    currentPage,
                    perPage: currentPerPage,
                  }}
                  paginationNavProps={{
                    currentPage,
                    handlePageChange,
                    pages: pagination?.pages || 1,
                    titleTable: 'Retailers',
                  }}
                />
              </Styled.TableContainer>
            }
            onMapViewFeatureSelected={onMapViewFeatureSelected}
            buttonObj={buttonObj}
            mapComponent={
              <MapView onMapViewPopUpButtonClicked={buttonObj} onMapViewFeatureSelected={onMapViewFeatureSelected} />
            }
          />
        </Styled.Wrapper>

        <FilterModal
          open={modalType === MODAL_NAMES.FILTER_MODAL}
          menu={FilterData}
          filterMapping={filterMapping}
          filterType={FILTER_TYPES.RETAILERS}
          onClose={closeModal}
          getApi={loadRetailers}
          setCurrentPage={setCurrentPage}
        />
      </PageContainer>
    </>
  );
};

Retailers.propTypes = {
  location: PropTypes.object,
};

Retailers.defaultProps = {
  location: {},
};

export default withRouter(Retailers);
