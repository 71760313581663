import { isEmpty } from 'lodash';
import { capitalizeFirstLetter } from './capitalizeFirstLetter';

const getRetailerData = (itemId, retailers) => {
  if (isEmpty(retailers)) return {};
  const retailerItem = retailers.find(({ id }) => id === itemId);
  const { name, address, address2, city, zipcode, closed_date: closedDate } = retailerItem.attributes;

  return {
    name,
    address,
    address2,
    city,
    zipcode,
    closedDate,
  };
};

const getUsersData = (arrayId, users) => {
  return users.filter((item) => arrayId?.includes(item.id));
};

const getAddress = (address, address2) => {
  if (address2) {
    return `${address}, ${address2}`;
  }
  return address;
};

const getUrl = (formId, retailerId, assignmentId) => {
  if (!formId || !retailerId || !assignmentId) return '';
  return `${formId}/${retailerId}/${assignmentId}`;
};

export const parseAssignmentsViewCampaign = ({ data, included }) => {
  if (isEmpty(included)) return [];
  const assignments = data?.map((item) => {
    const { attributes, relationships } = item;
    const retailerID = relationships.retailer.data.id;
    const formId = relationships.form.data.id;
    const userID = relationships.users.data.map((elem) => elem.id);
    const status = attributes?.status;
    const revisitNeeded = attributes?.is_follow_up;
    const visitViolation = attributes?.visit_violation;
    const visitId = attributes?.visit_id;
    const visitType = attributes?.visit_type;
    const retailers = included?.filter(({ type }) => type === 'Retailer');
    const users = included?.filter(({ type }) => type === 'User');
    const usersList = getUsersData(userID, users);
    const retailerData = getRetailerData(retailerID, retailers);
    const address = getAddress(retailerData.address, retailerData.address2);
    const assigned = relationships.users.meta.count > 0 ? 'Yes' : '-';

    const assignmentsItem = {
      retailer__name: capitalizeFirstLetter(retailerData.name),
      address,
      city: retailerData.city,
      zipcode: retailerData.zipcode,
      id: item.id,
      retailerID,
      status,
      usersList,
      userID,
      assignmentId: item.id,
      assigned,
      url: getUrl(formId, retailerID, item.id),
      revisitNeeded,
      visitId,
      visitType,
      visit_violation: visitViolation,
      closedDate: retailerData.closedDate,
    };
    return assignmentsItem;
  });

  return assignments;
};
