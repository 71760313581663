/* Libs */
import styled, { css } from 'styled-components';
import { DEVICES } from '_constants';
import { CLOSE_MODAL_ICON } from 'assets/icons';

/* Styles */
export const Title = styled.div`
  flex-grow: 1;
  font-size: 20px;
  color: var(--dark);
  text-align: left;
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 30px 40px 0;
`;

export const CancelIconWrapper = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--dark);
  background: transparent url(${CLOSE_MODAL_ICON}) no-repeat center center;
  padding: 0;
  border: none;
`;

export const OverallModalWrapper = styled.div`
  position: fixed;
  z-index: 900;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--dark30);
`;

export const Content = styled.div`
  position: relative;
  padding: ${({ withoutPadding }) => (withoutPadding ? '0' : '45px 38px 10px')};
  font-size: 16px;
  line-height: 1.25;
  color: var(--dark);
  box-sizing: border-box;

  @media ${DEVICES.tablet} {
    padding: ${({ withoutPadding }) => (withoutPadding ? '0' : '45px 15px 0')};
  }
`;

export const Modal = styled.div`
  position: relative;
  z-index: 100;
  /* TODO: think about modal content overflow and position */
  /* transform: translateY(-50%); */
  width: ${({ autoWidth }) => (autoWidth ? 'auto' : '630px')};
  max-height: calc(100vh - 40px);
  /* TODO: think about modal content overflow and position */
  padding: ${({ withoutPadding }) => (withoutPadding ? '0' : '0 0 32px')};
  background-color: var(--white);
  box-sizing: border-box;
  background-repeat: no-repeat;
  overflow-y: ${({ preventDesktopOverflow }) => (preventDesktopOverflow ? 'visible' : 'auto')};

  @media ${DEVICES.tablet} {
    max-width: 100vw;
    width: auto;
    height: ${({ autoHeight }) => (autoHeight ? 'auto' : '100%')};
    max-height: 100%;
    overflow-y: auto;
  }

  ${({ isFullScreen }) =>
    isFullScreen &&
    css`
      width: 100vw;
      height: 100vh;
      max-height: 100%;
      padding: 0;
      margin: 0;
      overflow-y: visible;

      ${Content} {
        padding: 0;
        height: 100%;
      }
    `}
`;
