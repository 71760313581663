/* eslint-disable react/prop-types */
import React, { memo, useMemo, useState, useCallback } from 'react';
import { compact, uniqueId } from 'lodash';
import LinkCell from 'modules/old/cells/LinkCell';
import HeaderCell from 'modules/old/cells/HeaderCell';
import TextCell from 'modules/old/cells/TextCell';
import { TableWrapper, BaseSelect, Img, Checkbox } from 'ui-kit';
import useSortTable from 'utils/old/useSortTable';
import getToastLock from 'utils/old/getAddToastLock';
import useToastContext from 'utils/old/useToastContext';
import { getVisitLinkText } from 'utils/custom';
import { URLS, VISIT_TYPE_LINKS } from '_constants';
import { TABLE_EDIT_PENCIL } from 'assets/icons';
import { COLUMNS, INITIAL_COLUMNS } from './constants';
import * as Styled from '../styles';

const STATUS_OPTIONS = [
  {
    label: 'Not started',
    value: 0,
  },
  {
    label: 'Completed',
    value: 1,
  },
  {
    label: 'In progress',
    value: 2,
  },
];

const getUrl = (formId, retailerId, assignmentId) => {
  if (!formId || !retailerId || !assignmentId) return '';
  return `${formId}/${retailerId}/${assignmentId}`;
};

const getInitialColumns = ({
  handleSort,
  sortOption,
  dumpedActiveColumns,
  handleChangeStatusSelect,
  handleHeaderCheckboxChange,
  tableCheckboxValues,
  toggleSelectAllCheckboxes,
  openModal,
  formId,
  userId,
}) =>
  compact([
    {
      Header: () => (
        <Styled.TableHeaderContainer>
          <Checkbox onChange={toggleSelectAllCheckboxes} name="test" />
        </Styled.TableHeaderContainer>
      ),
      width: 60,
      accessor: 'assigned',
      style: { padding: 0, overflow: 'visible' },
      Cell: ({ original }) => (
        <Checkbox
          value={tableCheckboxValues[`${original.retailerID}-${original.assignmentId}`]}
          onChange={handleHeaderCheckboxChange}
          name={`${original.retailerID}-${original.assignmentId}`}
          disabled={original.status === 1 || Boolean(original?.closedDate)}
          isManageAssignmentsTable
        />
      ),
    },
    dumpedActiveColumns.assigned && {
      Header: () => (
        <HeaderCell col="Assigned" sortBy="" sortOption={sortOption} handleSort={handleSort('')} isHideSorting />
      ),
      width: 140,
      accessor: 'assigned',
      Cell: ({ value }) => <TextCell isAssigned={value} text={value} />,
    },
    dumpedActiveColumns.retailer__name && {
      Header: () => (
        <HeaderCell col="Retailer name" sortBy="name" sortOption={sortOption} handleSort={handleSort('name')} />
      ),
      minWidth: 260,
      accessor: 'retailer__name',
      Cell: ({ value, original }) => {
        const usersIdList = original?.usersList?.map(({ id }) => id) || [];
        const retailerLinkText = original?.closedDate ? `${value} (closed: ${original?.closedDate})` : value;

        return getUrl(formId, original.retailerID, original.assignmentId) &&
          usersIdList.includes(userId) &&
          original?.status !== 1 &&
          original?.closedDate === null ? (
          <LinkCell
            id={uniqueId()}
            text={value}
            to={`${URLS.survey}/${getUrl(formId, original.retailerID, original.assignmentId)}`}
          />
        ) : (
          <LinkCell id={uniqueId()} text={retailerLinkText} to={`${URLS.retailers}/${original.retailerID}`} />
        );
      },
    },
    dumpedActiveColumns.address && {
      Header: () => (
        <HeaderCell col="Address" sortBy="address" sortOption={sortOption} handleSort={handleSort('address')} />
      ),
      minWidth: 150,
      accessor: 'address',
      Cell: ({ value }) => <TextCell text={value} />,
    },
    dumpedActiveColumns.city && {
      Header: () => <HeaderCell col="City" sortBy="city" sortOption={sortOption} handleSort={handleSort('city')} />,
      minWidth: 150,
      accessor: 'city',
      Cell: ({ value }) => <TextCell text={value} />,
    },
    dumpedActiveColumns.zipcode && {
      Header: () => (
        <HeaderCell col="Zip code" sortBy="zipcode" sortOption={sortOption} handleSort={handleSort('zipcode')} />
      ),
      minWidth: 150,
      accessor: 'zipcode',
      Cell: ({ value }) => <TextCell text={value} />,
    },
    dumpedActiveColumns.usersList && {
      Header: () => (
        <HeaderCell
          isHideSorting
          col="Data Collector(s)"
          sortBy="user"
          sortOption={sortOption}
          handleSort={handleSort('user')}
        />
      ),
      minWidth: 260,
      accessor: 'usersList',
      Cell: ({ value, original }) => {
        return (
          <Styled.ManageDataCollectorContainer>
            {value.length > 0 && (
              <>
                <div>
                  {value?.map((elem) => {
                    return <TextCell text={elem.attributes.email} />;
                  })}
                </div>
                {original.status !== 1 && (
                  <Styled.IconContainer onClick={() => openModal(original.id)}>
                    <Img alt="edit pencil" src={TABLE_EDIT_PENCIL} size={[12, 12]} />
                  </Styled.IconContainer>
                )}
              </>
            )}
          </Styled.ManageDataCollectorContainer>
        );
      },
    },
    dumpedActiveColumns.hasViolation && {
      Header: () => <HeaderCell isHideSorting col="Has violation" />,
      width: 120,
      accessor: 'visit_violation',
      Cell: ({ value }) => <TextCell text={value ? 'Yes' : ' '} />,
    },
    dumpedActiveColumns.visits && {
      Header: () => <HeaderCell isHideSorting col="Visit(s)" hasPopUp sortBy="visitId" />,
      width: 260,
      accessor: 'visitId',
      Cell: ({ original }) => {
        const { visitText, violationText } = getVisitLinkText(
          original?.status,
          original?.visitId,
          original?.revisitNeeded,
          original?.visit_violation,
        );

        return (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            {visitText && (
              <LinkCell
                id={uniqueId()}
                text={visitText}
                to={`${VISIT_TYPE_LINKS[original?.visitType]}/${original?.visitId}`}
              />
            )}
            {violationText && (
              <LinkCell
                id={uniqueId()}
                text={violationText}
                to={`${VISIT_TYPE_LINKS[original?.visitType]}/${original?.revisitNeeded}`}
              />
            )}
          </div>
        );
      },
    },
    dumpedActiveColumns.status && {
      Header: () => (
        <HeaderCell
          isHideSorting
          col="Status"
          sortBy="status_order"
          sortOption={sortOption}
          handleSort={handleSort('status_order')}
        />
      ),
      minWidth: 150,
      accessor: 'status',
      Cell: ({ value, original }) => {
        return (
          <Styled.DropInTableContainer>
            <BaseSelect
              value={STATUS_OPTIONS[value]}
              onChange={(val) => handleChangeStatusSelect(val.value, original.id)}
              inputId={`statusDrop${uniqueId()}`}
              options={STATUS_OPTIONS}
            />
          </Styled.DropInTableContainer>
        );
      },
    },
  ]);

const ManageAssignmentsTable = ({
  data,
  sortHandler,
  sortOption,
  handleChangeStatusSelect,
  handleHeaderCheckboxChange,
  tableCheckboxValues,
  toggleSelectAllCheckboxes,
  openModal,
  formId,
  userId,
}) => {
  const handleSort = useSortTable(sortOption, sortHandler);
  const [activeColumns, setActiveColumns] = useState(INITIAL_COLUMNS);
  const [isOpen, setIsOpen] = useState(false);
  const addToast = useToastContext();
  const [tooltipStyle, setTooltipStyle] = useState({ display: 'none' });
  const [tooltipContent, setTooltipContent] = useState('');

  const getToast = () => getToastLock('Assignments', addToast);

  const dumpedActiveColumns = useMemo(
    () => activeColumns.reduce((acc, value) => ({ ...acc, [value]: value }), {}),
    [activeColumns],
  );

  const tableControlParams = useMemo(
    () => ({
      activeColumns,
      columns: COLUMNS,
      setActiveColumns,
      setIsOpen,
      isOpen,
    }),
    [activeColumns, isOpen],
  );

  const editCellParams = useMemo(
    () => ({
      editText: 'youth',
      route: URLS.youthPages.edit,
      getToast,
    }),
    [],
  );

  const wrappedColumns = useMemo(
    () =>
      getInitialColumns({
        handleSort,
        sortOption,
        dumpedActiveColumns,
        tableControlParams,
        editCellParams,
        handleChangeStatusSelect,
        handleHeaderCheckboxChange,
        tableCheckboxValues,
        toggleSelectAllCheckboxes,
        openModal,
        getToast, // eslint-disable-next-line
        formId,
        userId,
      }),
    [dumpedActiveColumns, sortOption, tableControlParams, handleSort],
  );

  const getTrProps = useCallback((state, rowInfo) => {
    const rowData = rowInfo && rowInfo.original;

    if (rowData && rowData.revisitNeeded) {
      return {
        style: {
          background: '#FFC5C5',
        },
      };
    }
    return {};
  }, []);

  const handleRowHover = useCallback((event, rowInfo) => {
    const rowData = rowInfo && rowInfo.original;
    const distanceToEdge = 200;
    const tooltipLeft = event.clientX;
    if (
      event.target.classList.contains('input-wrapper') ||
      event.target.hasAttribute('disabled') ||
      event.target.hasAttribute('href')
    ) {
      return;
    }

    // Adjust left position if close to the right edge
    const left = window.innerWidth - tooltipLeft < distanceToEdge ? window.innerWidth - distanceToEdge : tooltipLeft;

    if (rowData && rowData.revisitNeeded) {
      setTooltipStyle({
        display: 'block',
        position: 'fixed',
        top: `${event.clientY}px`,
        left: `${left}px`,
      });

      setTooltipContent('Revisit');
    }
  }, []);

  const handleRowLeave = () => {
    setTooltipStyle({ display: 'none' });
  };

  const getTrPropsFunctions = {
    getTrProps,
    onMouseEnter: (event, rowInfo) => handleRowHover(event, rowInfo),
    onMouseLeave: handleRowLeave,
    tooltipContent,
    tooltipStyle,
  };

  return (
    <TableWrapper
      columns={wrappedColumns}
      data={data || []}
      tableControlParams={tableControlParams}
      isEdit
      editCellParams={editCellParams}
      getTrPropsFunctions={getTrPropsFunctions}
      isManageAssignmentsTable
    />
  );
};

export default memo(ManageAssignmentsTable);
