import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty, isNaN } from 'lodash';
import queryString from 'query-string';
import { PageContainer, AccessControl } from 'modules';
import { TableSizeControls, Loader, TableTabsWrapper, Button } from 'ui-kit';
import ExportDataDropDown from 'ui-kit/old/ExportDataDropDown';
import Filter from 'ui-kit/old/filter';
import { EXPORT_DATA_OPTIONS_NAMES } from '_constants/old/_constants/EXPORT_DATA';
import ITEMS_PER_PAGE_OPTIONS from '_constants/old/_constants/ITEMS_PER_PAGE_OPTIONS';
import DEFAULT_SORT from '_constants/old/_constants/DEFAULT_SORT';
import { newSavePageToPdf } from 'utils/custom';
import qsSearchApplyUtil from 'utils/old/qsSearchApplyUtil';
import { SetQueryParams } from 'utils/old/setQueryParams';
import { isValidDate } from 'utils/old/checkSearchDate';
import sortParam from 'utils/old/sortParam';
import { ChipList } from 'modules/Modals/old/FilterModal/components/ChipsList';
import { FILTER_TABS, FILTER_TYPES } from '_constants/old/filters';
import { FilterModal } from 'modules/Modals';
import { UserEntity, FilterEntity, AuthEntity } from '_entities';
import { BUTTON_TYPES, URLS, PERMISSIONS, MESSAGES, PAGE_TYPES } from '_constants';
import { fetchUsers } from '_entities/old/Users/users.service';
import { notification } from 'utils/services';
import UsersTable from './components/UserTable';
import filterMapping from './Filters/chips';
import * as Styled from './styles';

const { applyFilters } = FilterEntity.actions;
const { getUsers } = UserEntity.selectors;
const { getSecGeoList, usersCSV } = UserEntity.actions;
const { getUserRoleType, getUserId } = AuthEntity.selectors;

function UserList(props) {
  const {
    location: { search },
  } = props;
  const [exportValue, setExportValue] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modalType, setModalType] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [currentPerPage, setCurrentPerPage] = useState(ITEMS_PER_PAGE_OPTIONS[1].value);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOption, setSortOption] = useState({
    sortBy: DEFAULT_SORT.users.prop,
    sortOrder: DEFAULT_SORT.users.order,
  });
  const userList = useSelector(getUsers);
  const myUserRoleType = useSelector(getUserRoleType);
  const myUserId = useSelector(getUserId);
  const pagination = userList?.pagination;
  const data = userList?.data;
  const {
    oldFilter: { generalFilters },
  } = useSelector((state) => state);

  const closeModal = () => setModalType(null);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const handleModalType = (type) => setModalType(type);
  const MODAL_NAMES = { FILTER_MODAL: 'FILTER_MODAL' };

  const handleExportDataChange = ({ value }) => {
    setExportValue(value);
    switch (value) {
      case EXPORT_DATA_OPTIONS_NAMES.EXPORT:
        dispatch(usersCSV(search));
        notification.success(MESSAGES.COLLECT.SUCCESS);
        break;
      case EXPORT_DATA_OPTIONS_NAMES.PRINT:
        setLoading(true);
        // In order to show loader before html2canvas function start working we should wrap function in setTimeout
        setTimeout(() => newSavePageToPdf({ setExportLoading: setLoading, hasFilters: true }, PAGE_TYPES.TABLE), 0);
        break;
      default:
    }
  };

  const openFilterModal = () => {
    handleModalType(MODAL_NAMES.FILTER_MODAL);
  };

  const loadUsers = useCallback(
    async ({
      page = currentPage,
      perPage = currentPerPage,
      sortBy = sortOption.sortBy,
      sortOrder = sortOption.sortOrder,
      filters = generalFilters,
      ...rest
    } = {}) => {
      try {
        setLoading(true);
        await fetchUsers(
          {
            ...filters,
            ...rest,
            page,
            perPage,
            sortBy,
            sortOrder,
          },
          dispatch,
        );
        SetQueryParams({
          queryString: {
            ...filters,
            ...rest,
            page,
            page_size: perPage,
            ordering: sortParam({ sortBy, sortOrder }),
          },
          history,
        });
      } catch (e) {
        console.log('e', e);
      } finally {
        setLoading(false);
      }
    },
    [currentPage, dispatch, currentPerPage, SetQueryParams, generalFilters, sortOption],
  );

  const handleOnChangeInputSearch = (searchVal) => {
    if (isEmpty(searchVal)) {
      setSearchValue('');
      qsSearchApplyUtil({
        dispatch,
        getApi: loadUsers,
        generalFilters,
      });
    }
    setSearchValue(searchVal);
  };

  const handleClickInput = () => {
    if (isEmpty(searchValue)) return;
    const searchString = isValidDate(searchValue);
    qsSearchApplyUtil({
      search: searchString,
      dispatch,
      getApi: loadUsers,
      generalFilters,
    });
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      const searchString = isValidDate(searchValue);
      qsSearchApplyUtil({
        search: searchString,
        dispatch,
        getApi: loadUsers,
        generalFilters,
      });
    }
  };

  const onKeyDownFilter = (e) => {
    if (e.keyCode === 13) {
      handleModalType(MODAL_NAMES.FILTER_MODAL);
      e.preventDefault();
    }
  };

  const handleRemoveSearch = () => {
    if (isEmpty(searchValue)) return;
    qsSearchApplyUtil({
      dispatch,
      getApi: loadUsers,
      generalFilters,
    });
    setSearchValue('');
  };

  const handlePerPageChange = useCallback(
    ({ value }) => {
      if (currentPerPage === value) return;
      setCurrentPerPage(value);
      setCurrentPage(1);
      loadUsers({ perPage: value, page: 1 });
    },
    [setCurrentPerPage, setCurrentPage, loadUsers, currentPerPage],
  );

  const handlePageChange = useCallback(
    ({ value }) => {
      if (currentPage === value) return;
      setCurrentPage(value);
      loadUsers({ page: value });
    },
    [loadUsers, setCurrentPage, currentPage],
  );

  const sortHandler = useCallback(
    (sortBy, sortOrder) => {
      setSortOption({ sortBy, sortOrder });
      loadUsers({ sortBy, sortOrder });
    },
    [loadUsers, setSortOption],
  );

  const handleAddUser = () => {
    history.push(URLS.userPages.add);
  };

  const FilterData = [
    {
      name: FILTER_TABS.QUICK_QUERY,
      isActive: true,
    },
  ];

  const dropDownOptions = [
    {
      label: EXPORT_DATA_OPTIONS_NAMES.PRINT,
      value: EXPORT_DATA_OPTIONS_NAMES.PRINT,
    },
    {
      label: EXPORT_DATA_OPTIONS_NAMES.EXPORT,
      value: EXPORT_DATA_OPTIONS_NAMES.EXPORT,
    },
  ];

  useEffect(() => {
    const { page, page_size: pageSize, search: searchQuery, ...rest } = queryString.parse(location.search);
    const queryPage = isNaN(+page) || +page === 0 ? currentPage : +page;
    const queryPageSize = isNaN(+pageSize) || +pageSize === 0 ? currentPerPage : +pageSize;
    setCurrentPage(queryPage);
    setCurrentPerPage(queryPageSize);
    setSearchValue(searchQuery || '');

    const newFilters = {
      ...(searchQuery ? { search: searchQuery } : ''),
      ...rest,
    };
    dispatch(applyFilters({ page, page_size: pageSize, ...newFilters }));
    loadUsers({ filters: newFilters, page, perPage: pageSize });

    (async () => {
      try {
        await dispatch(getSecGeoList());
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <PageContainer>
      <Styled.Wrapper>
        <Styled.Header className="table-header">
          {loading && <Loader />}
          <Styled.H1>Users</Styled.H1>
          <Styled.HeaderWrapper>
            <ExportDataDropDown
              options={dropDownOptions}
              onChange={handleExportDataChange}
              value={exportValue}
              placeholder="Export & print"
            />
            <AccessControl permission={PERMISSIONS.ADD_USER}>
              <Button data-html2canvas-ignore text="Add user" variant={BUTTON_TYPES.DANGER} onClick={handleAddUser} />
            </AccessControl>
          </Styled.HeaderWrapper>
        </Styled.Header>
        <Styled.FilterWrapper data-html2canvas-ignore>
          <Filter
            type="button"
            placeholder="Search users"
            handleClick={openFilterModal}
            onChangeInput={handleOnChangeInputSearch}
            handleClickInput={handleClickInput}
            onKeyDown={handleKeyDown}
            onKeyDownFilter={onKeyDownFilter}
            onRemoveSearch={handleRemoveSearch}
            searchValue={searchValue}
            isWidth
          />
        </Styled.FilterWrapper>
        <Styled.ChipsWrapper className="table-filters">
          <ChipList withoutScroll filterMapping={filterMapping} getApi={loadUsers} setCurrentPage={setCurrentPage} />
        </Styled.ChipsWrapper>
        <TableTabsWrapper
          amount={`${pagination?.count}` || 0}
          tableComponent={
            <Styled.TableContainer>
              <UsersTable
                data={data}
                sortOption={sortOption}
                sortHandler={sortHandler}
                myUserRoleType={myUserRoleType}
                myUserId={myUserId}
              />
              <TableSizeControls
                itemsPerPageProps={{
                  handlePerPageChange,
                  value: currentPerPage,
                }}
                pageCountProps={{
                  value: currentPage,
                  pages: pagination?.pages || 1,
                  handlePageChange,
                }}
                paginationInfoProps={{
                  total: pagination?.count || 0,
                  currentPage,
                  perPage: currentPerPage,
                }}
                paginationNavProps={{
                  currentPage,
                  handlePageChange,
                  pages: pagination?.pages || 1,
                  titleTable: 'Enforcements',
                }}
              />
            </Styled.TableContainer>
          }
        />
        <FilterModal
          open={modalType === MODAL_NAMES.FILTER_MODAL}
          menu={FilterData}
          filterMapping={filterMapping}
          filterType={FILTER_TYPES.USERS}
          onClose={closeModal}
          getApi={loadUsers}
          setCurrentPage={setCurrentPage}
        />
      </Styled.Wrapper>
    </PageContainer>
  );
}

UserList.propTypes = {
  location: PropTypes.object.isRequired,
};

export default UserList;
