import { URLS, PERMISSIONS } from '_constants';

const STORIES = {
  id: 4,
  title: 'stories',
  modularity: true,
  permission: [
    PERMISSIONS.VIEW_SNAPSHOT_MAPS,
    PERMISSIONS.DISPARATE_BURDEN_VIEW,
    PERMISSIONS.VIEW_ENFOCEMENT_OVERVIEW,
    PERMISSIONS.VIEW_POST_LANDSCAPE,
    PERMISSIONS.VIEW_RETAILER_REDUCTION,
    PERMISSIONS.VIEW_YOUTH_APPEAL,
  ],
  subRoutes: [
    {
      id: 1,
      title: 'snapshot maps',
      path: URLS.snapshotMaps,
      isShow: true,
      border: true,
      modularity: true,
      permission: PERMISSIONS.VIEW_SNAPSHOT_MAPS,
    },
    {
      id: 2,
      title: 'disparate burden',
      path: URLS.disparateBurden,
      isShow: true,
      devider: 'Infographics',
      modularity: true,
      // This is for short time and will be deleted when infographics will be in release
      isInfo: true,
      permission: PERMISSIONS.DISPARATE_BURDEN_VIEW,
    },
    {
      id: 3,
      title: 'enforcement overview',
      path: URLS.enforcementOverview,
      isShow: true,
      isInfo: true,
      modularity: true,
      permission: PERMISSIONS.VIEW_ENFOCEMENT_OVERVIEW,
    },
    {
      id: 4,
      title: 'point of sale landscape',
      path: URLS.posLandscape,
      isShow: true,
      isInfo: true,
      modularity: true,
      permission: PERMISSIONS.VIEW_POST_LANDSCAPE,
    },

    {
      id: 5,
      title: 'retailer reduction',
      path: URLS.retailerReduction,
      isShow: true,
      isInfo: true,
      modularity: true,
      permission: PERMISSIONS.VIEW_RETAILER_REDUCTION,
    },
    {
      id: 6,
      title: 'youth appeal',
      path: URLS.youthAppeal,
      isShow: true,
      isInfo: true,
      modularity: true,
      permission: PERMISSIONS.VIEW_YOUTH_APPEAL,
    },
  ],
  isShow: true,
};

const DASHBOARD = {
  id: 1,
  path: URLS.dashboard,
  title: 'dashboard',
  isShow: true,
};

const COLLECT = {
  id: 2,
  title: 'collect',
  permission: PERMISSIONS.VIEW_ASSIGNMENT || PERMISSIONS.VIEW_ASSIGNMENT,
  subRoutes: [
    {
      id: 1,
      title: 'assignments',
      path: URLS.assignments,
      isShow: true,
      permission: PERMISSIONS.VIEW_ASSIGNMENT,
    },
    {
      id: 2,
      title: 'campaign list',
      path: URLS.campaign_list,
      isShow: true,
      border: true,
      permission: PERMISSIONS.VIEW_ASSIGNMENT,
    },
  ],
  isShow: true,
};

const DATA = {
  id: 3,
  title: 'data',
  subRoutes: [
    {
      id: 1,
      title: 'retailers',
      path: URLS.retailers,
      isShow: true,
      permission: PERMISSIONS.VIEW_RETAILER,
    },
    {
      id: 2,
      title: 'store merger',
      path: URLS.storeMerger,
      isShow: true,
      border: true,
      permission: PERMISSIONS.VIEW_SIMILARSTORES,
    },
    {
      id: 3,
      title: 'Visits',
      isShow: true,
      permission: [
        PERMISSIONS.VIEW_VISIT,
        PERMISSIONS.VIEW_ASSESSMENT_VISITS,
        PERMISSIONS.VIEW_EDUCATION_VISITS,
        PERMISSIONS.VIEW_ENFORCEMENT_VISITS,
      ],
      isDevider: true,
    },
    {
      id: 4,
      title: 'assessments',
      path: URLS.assessments,
      isShow: true,
      isVisit: true,
      permission: [PERMISSIONS.VIEW_VISIT, PERMISSIONS.VIEW_ASSESSMENT_VISITS],
    },
    {
      id: 5,
      title: 'education',
      path: URLS.education,
      isShow: true,
      isVisit: true,
      permission: [PERMISSIONS.VIEW_VISIT, PERMISSIONS.VIEW_EDUCATION_VISITS],
    },

    {
      id: 6,
      title: 'enforcement',
      path: URLS.enforcement,
      isShow: true,
      isVisit: true,
      permission: [PERMISSIONS.VIEW_VISIT, PERMISSIONS.VIEW_ENFORCEMENT_VISITS],
    },
    {
      id: 7,
      title: 'reports',
      path: URLS.reports,
      isShow: true,
      borderAll: true,
      modularity: true,
      permission: [
        PERMISSIONS.VIEW_SUMMARY_REPORT_AREA,
        PERMISSIONS.VIEW_REPORT_ENFORCEMENT_OVERVIEW,
        PERMISSIONS.VIEW_REPORT_EDUCATION_OVERVIEW,
      ],
    },
    {
      id: 8,
      title: 'venues',
      path: URLS.venues,
      isShow: true,
      border: true,
      permission: PERMISSIONS.VIEW_VENUE,
    },
    {
      id: 9,
      title: 'youth',
      path: URLS.youth,
      isShow: true,
      permission: PERMISSIONS.VIEW_YOUTH_LINK,
    },
    {
      id: 10,
      title: 'audit logs',
      path: URLS.auditLogs,
      isShow: true,
      permission: PERMISSIONS.VIEW_AUDIT_LOG,
    },
  ],
  isShow: true,
};

const USERS = {
  id: 5,
  title: 'users',
  path: URLS.users,
  isShow: true,
  permission: PERMISSIONS.VIEW_USER,
};

const HELP = {
  id: 6,
  title: 'help',
  subRoutes: [],
  isShow: true,
};

const EMAIL = {
  id: 7,
  title: 'email',
  subRoutes: [
    {
      id: 1,
      title: 'log out',
      path: URLS.logout,
      isShow: true,
    },
  ],
  isShow: true,
};

const LOGIN = {
  id: 8,
  title: 'Login',
  path: URLS.login,
  isShow: true,
};

const getDashboardInfoSections = (dashboardInfoSections) =>
  dashboardInfoSections.map(({ title, link }, index) => ({
    id: index + 1,
    title,
    path: link,
    isShow: true,
    isHelp: true,
    href: link.includes('@') ? `mailto:${link}` : link,
  }));

export const getMenuRoutes = (isAuthenticated, userEmail = 'email', isOffline = false, dashboardInfoSections = []) => {
  return isAuthenticated
    ? isOffline
      ? [COLLECT, { ...EMAIL, title: userEmail }]
      : [
          DASHBOARD,
          COLLECT,
          DATA,
          STORIES,
          USERS,
          { ...HELP, subRoutes: getDashboardInfoSections(dashboardInfoSections) },
          { ...EMAIL, title: userEmail },
        ]
    : [STORIES, LOGIN];
};
