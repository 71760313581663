import queryString from 'query-string';
import { isUndefined, pickBy } from 'lodash';
import sortParam from './sortParam';

export default ({ page, perPage, sortBy, sortOrder, ...params }) =>
  queryString.stringify(
    pickBy(
      {
        ...params,
        page,
        page_size: perPage,
        ordering: sortParam({ sortBy, sortOrder }),
      },
      (item) => !isUndefined(item),
    ),
  );
