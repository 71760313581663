import { API_URLS, REQUEST_HEADERS } from '_constants';
import { visitFormUrls } from './constants';
import * as TYPES from './Visit.types';

export const getVisitInfo = (id) => ({
  types: [TYPES.GET_VISIT_INFO, TYPES.GET_VISIT_INFO_SUCCESS, TYPES.GET_VISIT_INFO_FAIL],
  promise: (client) =>
    client.get(
      `${API_URLS.visits}/${id}?include=agency,form,retailer,user,modified_by,campaign,violation_info_last_modified_by&visitId=${id}`,
    ),
});

export const sendViolationPDF = (id, data) => ({
  types: [TYPES.SEND_VISIT_VIOLATION, TYPES.SEND_VISIT_VIOLATION_SUCCESS, TYPES.SEND_VISIT_VIOLATION_FAIL],
  promise: (client) => client.post(`${API_URLS.visits}/${id}/violations`, data),
});

export const getViolationInfo = (id) => ({
  types: [TYPES.GET_VIOLATION_INFO, TYPES.GET_VIOLATION_INFO_SUCCESS, TYPES.GET_VIOLATION_INFO_FAIL],
  promise: (client) => client.get(`${API_URLS.visits}/${id}/violation-info`),
});

export const createVisit = (data) => ({
  types: [TYPES.ADD_VISIT, TYPES.ADD_VISIT_SUCCESS, TYPES.ADD_VISIT_FAIL],
  promise: (client) => client.post(API_URLS.visits, data, { headers: { ...REQUEST_HEADERS } }),
});

export const updateVisit = (data, id) => ({
  types: [TYPES.UPDATE_VISIT, TYPES.UPDATE_VISIT_SUCCESS, TYPES.UPDATE_VISIT_FAIL],
  promise: (client) => client.patch(`${API_URLS.visits}/${id}`, data, { headers: { ...REQUEST_HEADERS } }),
});

export const updateViolation = (data, id) => ({
  types: [TYPES.UPDATE_VIOLATION, TYPES.UPDATE_VIOLATION_SUCCESS, TYPES.UPDATE_VIOLATION_FAIL],
  promise: (client) => client.patch(`${API_URLS.visits}/${id}/violation-info`, data),
});

export const getAssesmentList = (params, timeZone) => {
  return {
    types: [
      TYPES.GET_VISIT_ASSESSMENT_LIST,
      TYPES.GET_VISIT_ASSESSMENT_LIST_SUCCESS,
      TYPES.GET_VISIT_ASSESSMENT_LIST_FAIL,
    ],
    promise: (client) => client.get(`${API_URLS.assessments}?${params}&include=retailer,agency,user,campaign`),
    timeZone,
  };
};

export const getEducationList = (params, timeZone) => {
  return {
    types: [
      TYPES.GET_VISIT_EDUCATION_LIST,
      TYPES.GET_VISIT_EDUCATION_LIST_SUCCESS,
      TYPES.GET_VISIT_EDUCATION_LIST_FAIL,
    ],
    promise: (client) => client.get(`${API_URLS.educations}?${params}&include=retailer,agency,user,campaign`),
    timeZone,
  };
};

export const setVisitInfo = (payload) => ({
  type: TYPES.SET_VISIT_INFO,
  payload,
});

export const getVisitList = (params) => ({
  types: [TYPES.GET_VISIT_LIST, TYPES.GET_VISIT_LIST_SUCCESS, TYPES.GET_VISIT_LIST_FAIL],
  promise: (client) => client.get(`${API_URLS.visits}?${params}`),
});

export const getVisitFormList = (visitType, params = '') => ({
  types: [TYPES.GET_VISITS_FORMS, TYPES.GET_VISITS_FORMS_SUCCESS, TYPES.GET_VISITS_FORMS_FAIL],
  promise: (client) => client.get(`${visitFormUrls[visitType]}/get_forms${params ? `?${params}` : ''}`),
});

export const getVisitListCSV = (visitType, idList, filters = '') => ({
  types: [TYPES.GET_VISITS_LIST_CSV, TYPES.GET_VISITS_LIST_CSV_SUCCESS, TYPES.GET_VISITS_LIST_CSV_FAIL],
  promise: (client) => client.get(`${visitFormUrls[visitType]}?forms=${idList}&${filters}`),
});

export const resetVisitData = () => ({
  type: TYPES.RESET_VISIT_DATA,
});

export const resetViolationData = () => ({
  type: TYPES.RESET_VIOLATION_INFO,
});

export const getEnforcementViolation = (params) => ({
  types: [TYPES.GET_ENFOCEMENT_VIOLATION, TYPES.GET_ENFOCEMENT_VIOLATION_SUCCESS, TYPES.GET_ENFOCEMENT_VIOLATION_FAIL],
  promise: (client) => client.get(`${API_URLS.retailers}/${params}/${API_URLS.enforcementViolations}`),
});

export const getEducationViolation = (params) => ({
  types: [TYPES.GET_EDUCATION_VIOLATION, TYPES.GET_EDUCATION_VIOLATION_SUCCESS, TYPES.GET_EDUCATION_VIOLATION_FAIL],
  promise: (client) => client.get(`${API_URLS.retailers}/${params}/${API_URLS.educationViolations}`),
});
