import React, { memo, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import { EDIT_PEN, ADD_SURVEY } from 'assets/icons';
import { Img, Checkbox } from 'ui-kit';
import { handleEnterPress } from 'utils/custom';
import { PERMISSIONS, USER_ROLE_TYPES } from '_constants';
import { AccessControl } from 'modules';
import * as Styled from './styles';

const { ACCOUNT_MANAGER, MULTI_ACCOUNT_MANAGER, AGENCY_MANAGER, CAMPAIGN_MANAGER, DATA_COLLECTOR } = USER_ROLE_TYPES;

const ActionCell = ({
  original,
  route,
  editText,
  suspensionAction,
  usersAction,
  agenciesAction,
  setOpenSurveyModal,
  onlyEdit,
  isCheckBox,
  name,
  value,
  handleCheckbox,
}) => {
  const history = useHistory();

  const path = useMemo(() => (route ? `${route}/${original?.id}` : ''), [original, route]);

  const editAccess = useMemo(() => {
    switch (editText) {
      case 'enforcement':
      case 'education':
      case 'assessment': {
        return {
          permission: PERMISSIONS.CHANGE_VISIT,
          ruleSet: {
            assigned: [DATA_COLLECTOR],
            agency: [AGENCY_MANAGER],
            agencySecGeogs: [CAMPAIGN_MANAGER],
            all: [ACCOUNT_MANAGER, MULTI_ACCOUNT_MANAGER],
          },
        };
      }
      case 'retailer': {
        return {
          permission: PERMISSIONS.CHANGE_RETAILER,
          ruleSet: {
            secGeogs: [DATA_COLLECTOR, AGENCY_MANAGER, CAMPAIGN_MANAGER, ACCOUNT_MANAGER],
            all: [MULTI_ACCOUNT_MANAGER],
          },
        };
      }
      case 'youth': {
        return {
          permission: PERMISSIONS.CHANGE_YOUTH,
          ruleSet: {
            agencySecGeogs: [ACCOUNT_MANAGER, AGENCY_MANAGER, CAMPAIGN_MANAGER],
            all: [MULTI_ACCOUNT_MANAGER],
          },
        };
      }
      case 'edit venues': {
        return {
          permission: PERMISSIONS.CHANGE_VENUE,
          ruleSet: {
            secGeogs: [DATA_COLLECTOR, AGENCY_MANAGER, CAMPAIGN_MANAGER, ACCOUNT_MANAGER],
            all: [MULTI_ACCOUNT_MANAGER],
          },
        };
      }
      case 'edit user': {
        return {
          permission: PERMISSIONS.CHANGE_USER,
        };
      }
      case 'campaign': {
        return {
          permission: PERMISSIONS.CHANGE_CAMPAIGN,
          ruleSet: {
            campaign: [CAMPAIGN_MANAGER],
            agency: [AGENCY_MANAGER],
            all: [ACCOUNT_MANAGER, MULTI_ACCOUNT_MANAGER],
          },
        };
      }
      default:
        return true;
    }
  }, [editText]);

  // eslint-disable-next-line consistent-return
  const handleEditClick = () => {
    if (usersAction) {
      return usersAction(original.id);
    }
    if (agenciesAction) {
      return agenciesAction(original.id);
    }
    if (suspensionAction) {
      return suspensionAction(original.suspensionItem);
    }
    history.push(path);
  };

  const handleSurveyClick = () => {
    setOpenSurveyModal(true, original.id);
  };

  const entityCampaign = original?.campaign?.data ? original?.campaign : { data: { id: original?.campaign?.id } };
  const entityData = {
    secGeog: Array.isArray(original.sec_geogs) ? original.sec_geogs : original.sec_geogs?.data[0]?.id,
    agency: original.agency,
    agencyId: original.agencyId,
    campaign: entityCampaign,
    campaign_manager: original.campaign_manager,
    data_collector: original.dataCollectors,
    campaignSet: original.campaignSet,
    user: original.user,
  };

  const roleRuleSetSurvey = {
    secGeogs: [AGENCY_MANAGER, ACCOUNT_MANAGER, DATA_COLLECTOR, CAMPAIGN_MANAGER],
    all: [MULTI_ACCOUNT_MANAGER],
  };

  return isCheckBox ? (
    <AccessControl permission={editAccess.permission} data={entityData} roleRuleSet={editAccess.ruleSet}>
      <Checkbox name={name} value={value} onChange={handleCheckbox} />
    </AccessControl>
  ) : (
    <Styled.CellWrapper>
      {!onlyEdit && (
        <AccessControl permission={PERMISSIONS.ALLOW_ANY_VISIT} data={entityData} roleRuleSet={roleRuleSetSurvey}>
          <Img
            tabIndex={0}
            id={uniqueId()}
            onKeyDown={(e) => handleEnterPress(e, handleSurveyClick)}
            onClick={handleSurveyClick}
            size={[22, 22]}
            src={ADD_SURVEY}
            alt="Survey this store"
          />
        </AccessControl>
      )}
      <AccessControl permission={editAccess.permission} data={entityData} roleRuleSet={editAccess.ruleSet}>
        <Img
          tabIndex={0}
          id={uniqueId()}
          onKeyDown={(e) => handleEnterPress(e, handleEditClick)}
          onClick={handleEditClick}
          size={[22, 22]}
          src={EDIT_PEN}
          alt="Edit this item"
        />
      </AccessControl>
      <Styled.Title>{`Edit ${editText}`}</Styled.Title>
    </Styled.CellWrapper>
  );
};

ActionCell.propTypes = {
  original: PropTypes.object.isRequired,
  agenciesAction: PropTypes.func,
  usersAction: PropTypes.func,
  suspensionAction: PropTypes.func,
  setOpenSurveyModal: PropTypes.func,
  route: PropTypes.string.isRequired,
  editText: PropTypes.string,
  onlyEdit: PropTypes.bool,
  isCheckBox: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.bool,
  handleCheckbox: PropTypes.func,
};

ActionCell.defaultProps = {
  agenciesAction: null,
  usersAction: null,
  suspensionAction: null,
  setOpenSurveyModal: () => {},
  editText: '',
  onlyEdit: false,
  isCheckBox: false,
  name: '',
  value: false,
  handleCheckbox: () => {},
};

export default memo(ActionCell);
