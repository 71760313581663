import { formIsValid, isDropValid } from 'utils/validation';
import { validateText } from 'utils/validation/fields';
import {
  FORM_ACTION_TYPES,
  FORM_REQUIRED_FIELDS_FULL_LIST,
  formInitialState,
  formNames,
  FORM_REQUIRED_FIELDS,
} from './constants';

export const init = (initialState) => {
  return { ...initialState };
};

export const validateData = (action, isFullList) => {
  switch (action.name) {
    case formNames.secGeogs:
    case formNames.forms:
      return isDropValid(action.payload, action.name);
    default:
      return validateText({
        value: action.payload.trim(),
        name: action.name,
        required: isFullList
          ? FORM_REQUIRED_FIELDS_FULL_LIST.includes(action.name)
          : FORM_REQUIRED_FIELDS.includes(action.name),
        max: 50,
      });
  }
};

const checkFormIsValid = (data, errors, isFullList) =>
  formIsValid(errors, isFullList ? FORM_REQUIRED_FIELDS_FULL_LIST : FORM_REQUIRED_FIELDS);

export const formReducer = (formState, action) => {
  const {
    data: {
      attributes: { include_all_retailers: isFullList },
    },
  } = formState;
  switch (action.type) {
    case FORM_ACTION_TYPES.ENTER_DATA: {
      const newData = {
        ...formState.data,
        attributes: {
          ...formState.data.attributes,
          [action.name]: action.payload,
        },
      };

      const newErrors = {
        ...formState.errors,
        [action.name]: false,
      };

      return {
        ...formState,
        data: newData,
        errors: newErrors,
        canBeSubmitted: checkFormIsValid(newData, newErrors, !isFullList),
      };
    }

    case FORM_ACTION_TYPES.VALIDATE_DATA: {
      const newErrors = {
        ...formState.errors,
        [action.name]: validateData(action, !isFullList),
      };

      return {
        ...formState,
        errors: newErrors,
        canBeSubmitted: checkFormIsValid(formState.data, newErrors, !isFullList),
      };
    }

    case FORM_ACTION_TYPES.RESET_DATA: {
      const newData = {
        ...formState.data,
        attributes: {
          ...action.payload,
        },
      };

      return {
        ...formState,
        data: newData,
        errors: false,
        canBeSubmitted: false,
      };
    }

    case FORM_ACTION_TYPES.SET_ALL_OPTIONS: {
      const newData = {
        ...formState.data,
        attributes: {
          ...formState.data.attributes,
          [formNames.sellTobacco]: [],
          [formNames.sellAlcohol]: [],
          [formNames.type]: [],
          [formNames.sampleSize]: '',
          [formNames.overSamplingPercentage]: '20',
        },
      };

      return {
        ...formState,
        data: newData,
        canBeSubmitted: checkFormIsValid(newData, formState.errors, !isFullList),
      };
    }

    default:
      return {
        ...formInitialState,
      };
  }
};

export const getRadioValue = (val) => {
  if (val === false) return false;
  if (val) return true;
  return null;
};
