import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PageContainer, AccessControl } from 'modules';
import { isEmpty, isNaN } from 'lodash';
import queryString from 'query-string';
import { isValidDate } from 'utils/old/checkSearchDate';
import Filter from 'ui-kit/old/filter';
import qs from 'qs';
import { FilterEntity, VenueEntity, NewFiltersEntity } from '_entities';
import { FilterModal, MapPopup } from 'modules/Modals';
import { ChipList } from 'modules/Modals/old/FilterModal/components/ChipsList';
import { SetQueryParams } from 'utils/old/setQueryParams';
import sortParam from 'utils/old/sortParam';
import ITEMS_PER_PAGE_OPTIONS from '_constants/old/_constants/ITEMS_PER_PAGE_OPTIONS';
import DEFAULT_SORT from '_constants/old/_constants/DEFAULT_SORT';
import { TableSizeControls, Loader, TableTabsWrapper, Button } from 'ui-kit';
import qsSearchApplyUtil from 'utils/old/qsSearchApplyUtil';
import { EXPORT_DATA_OPTIONS_NAMES } from '_constants/old/_constants/EXPORT_DATA';
import ExportDataDropDown from 'ui-kit/old/ExportDataDropDown';
import processQSParams from 'utils/old/processQSParams';
import { FILTER_TYPES } from '_constants/old/filters';
import { generateQSParams, newSavePageToPdf } from 'utils/custom';
import { notification } from 'utils/services';
import { MESSAGES, BUTTON_TYPES, URLS, PERMISSIONS, PAGE_TYPES } from '_constants';
import { ENTITY_TYPE } from 'modules/Modals/old/mapPopup/components/constants';
import VenuesTable from './components/VenuesTable';
import VenuesMapView from './components/VenuesMapView';
import * as Styled from './styles';
import filterMapping from './Filters/chips';
import { FILTER_DATA, TITLES, MODAL_NAMES, EXCEPTION_FIELDS } from './constants';

const { applyFilters } = FilterEntity.actions;
const { getVenueList, getVenueCSV } = VenueEntity.actions;
const { getVenues } = VenueEntity.selectors;
const { getLocationMapping } = NewFiltersEntity.actions;

const VenueList = () => {
  const [activeTab, setActiveTab] = useState(TITLES[0].id);
  const [exportValue, setExportValue] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [currentPerPage, setCurrentPerPage] = useState(ITEMS_PER_PAGE_OPTIONS[1].value);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOption, setSortOption] = useState({
    sortBy: DEFAULT_SORT.agencies.prop,
    sortOrder: DEFAULT_SORT.agencies.order,
  });

  const [loading, setLoading] = useState(false);

  const [isMapPopup, setOpenMapPopup] = useState(false);
  const [buttonObj, setButtonObj] = useState();
  const [modalType, setModalType] = useState(null);
  const [venueId, setVenueId] = useState(null);

  const handleModalType = (type) => setModalType(type);
  const closeModal = () => setModalType(null);

  const onChangeTabs = (titleTab) => {
    const title = TITLES.find((item) => item.value === titleTab).id;
    setActiveTab(title);
  };

  const { pagination, data } = useSelector(getVenues);

  const {
    oldFilter: { generalFilters, shadowFilters },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const loadVenues = useCallback(
    async ({
      page = currentPage,
      perPage = currentPerPage,
      sortBy = sortOption.sortBy,
      sortOrder = sortOption.sortOrder,
      filters = generalFilters,
      ...rest
    } = {}) => {
      try {
        setLoading(true);
        const params = processQSParams({
          ...filters,
          ...rest,
          page,
          perPage,
          sortOrder,
          sortBy,
        });
        await dispatch(getVenueList(params));
        SetQueryParams({
          queryString: {
            ...filters,
            ...rest,
            page,
            page_size: perPage,
            ordering: sortParam({ sortBy, sortOrder }),
          },
          history,
        });
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    },
    [currentPage, dispatch, currentPerPage, generalFilters, sortOption, history],
  );

  const sortHandler = useCallback(
    (sortBy, sortOrder) => {
      setSortOption({ sortBy, sortOrder });
      loadVenues({ sortBy, sortOrder });
    },
    [loadVenues, setSortOption],
  );

  const handlePageChange = useCallback(
    ({ value }) => {
      if (currentPage === value) return;
      setCurrentPage(value);
      loadVenues({ page: value });
    },
    [loadVenues, setCurrentPage],
  );

  const handlePerPageChange = useCallback(
    ({ value }) => {
      if (currentPerPage === value) return;
      setCurrentPerPage(value);
      setCurrentPage(1);
      loadVenues({ perPage: value, page: 1 });
    },
    [setCurrentPerPage, setCurrentPage, loadVenues],
  );

  useEffect(() => {
    const { page, page_size: pageSize, search: searchQuery, ...rest } = queryString.parse(location.search);
    const queryPage = isNaN(+page) || +page === 0 ? currentPage : +page;
    const queryPageSize = isNaN(+pageSize) || +pageSize === 0 ? currentPerPage : +pageSize;
    setCurrentPage(queryPage);
    setCurrentPerPage(queryPageSize);
    setSearchValue(searchQuery || '');

    const newFilters = {
      ...(searchQuery ? { search: searchQuery } : ''),
      ...rest,
    };
    dispatch(applyFilters({ page, page_size: pageSize, ...newFilters }));
    loadVenues({ filters: newFilters, page, perPage: pageSize });
    dispatch(getLocationMapping());
  }, []);

  const onMapViewFeatureSelected = (mapFeature) => {
    if (mapFeature) {
      setVenueId(mapFeature.id);
      setOpenMapPopup(true);
    } else {
      setOpenMapPopup(false);
    }
  };

  const handleRemoveSearch = () => {
    if (isEmpty(searchValue)) return;
    qsSearchApplyUtil({
      dispatch,
      getApi: loadVenues,
      generalFilters,
    });
    setSearchValue('');
  };

  const handleOnChangeInputSearch = (searchVal) => {
    if (isEmpty(searchVal)) {
      setSearchValue('');
      qsSearchApplyUtil({
        dispatch,
        getApi: loadVenues,
        generalFilters,
      });
    }
    setSearchValue(searchVal);
  };

  const handleClickInput = () => {
    if (isEmpty(searchValue)) return;
    const searchString = isValidDate(searchValue);
    qsSearchApplyUtil({
      search: searchString,
      dispatch,
      getApi: loadVenues,
      generalFilters,
    });
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      const searchString = isValidDate(searchValue);
      qsSearchApplyUtil({
        search: searchString,
        dispatch,
        getApi: loadVenues,
        generalFilters,
      });
    }
  };

  const handleAddVenue = () => {
    history.push(URLS.venuesPages.add);
  };

  const getVenuesCSV = async () => {
    try {
      const newFilters = Object.keys(shadowFilters).reduce((acc, item) => {
        if (EXCEPTION_FIELDS.includes(item)) return acc;
        acc[item] = shadowFilters[item];
        return acc;
      }, {});
      const params = generateQSParams(newFilters);
      await dispatch(getVenueCSV(params));
      notification.success(MESSAGES.COLLECT.SUCCESS);
    } catch (err) {
      console.log(err);
      notification.error(MESSAGES.COMMON_ERROR);
    }
  };

  const handleExportDataChange = ({ value }) => {
    setExportValue(value);
    switch (value) {
      case EXPORT_DATA_OPTIONS_NAMES.EXPORT:
        getVenuesCSV();
        break;
      case EXPORT_DATA_OPTIONS_NAMES.PRINT:
        setLoading(true);
        // In order to show loader before html2canvas function start working we should wrap function in setTimeout
        setTimeout(() => newSavePageToPdf({ setExportLoading: setLoading, hasFilters: true }, PAGE_TYPES.TABLE), 0);
        break;
      default:
    }
  };

  const onKeyDownFilter = (e) => {
    if (e.keyCode === 13) {
      handleModalType(MODAL_NAMES.FILTER_MODAL);
      e.preventDefault();
    }
  };

  const openFilterModal = () => {
    handleModalType(MODAL_NAMES.FILTER_MODAL);
  };

  useEffect(() => {
    const searchObj = qs.parse(location.search?.split('?')[1]);
    dispatch(applyFilters(searchObj));
  }, []);

  return (
    <>
      <PageContainer>
        {loading && <Loader />}
        {isMapPopup && (
          <MapPopup
            entityId={venueId || 17174}
            onClose={setOpenMapPopup}
            setButtonObj={setButtonObj}
            entityType={ENTITY_TYPE.VENUE}
          />
        )}
        <Styled.Wrapper>
          <Styled.Header className="table-header">
            <Styled.H1>Venues</Styled.H1>
            <Styled.HeaderWrapper>
              <ExportDataDropDown onChange={handleExportDataChange} value={exportValue} />
              <AccessControl permission={PERMISSIONS.ADD_VENUE} data-html2canvas-ignore>
                <Button
                  data-html2canvas-ignore
                  text="Add venue"
                  variant={BUTTON_TYPES.DANGER}
                  onClick={handleAddVenue}
                />
              </AccessControl>
            </Styled.HeaderWrapper>
          </Styled.Header>
          <Styled.FilterWrapper data-html2canvas-ignore>
            <Filter
              type="button"
              placeholder="Search venue"
              handleClick={openFilterModal}
              onChangeInput={handleOnChangeInputSearch}
              handleClickInput={handleClickInput}
              onKeyDown={handleKeyDown}
              onKeyDownFilter={onKeyDownFilter}
              onRemoveSearch={handleRemoveSearch}
              searchValue={searchValue}
              isWidth
            />
          </Styled.FilterWrapper>
          <Styled.ChipsWrapper className="table-filters">
            <ChipList withoutScroll filterMapping={filterMapping} getApi={loadVenues} setCurrentPage={setCurrentPage} />
          </Styled.ChipsWrapper>
          <TableTabsWrapper
            hasTabs
            onChangeTabs={onChangeTabs}
            activeTab={activeTab}
            tabOptions={TITLES}
            amount={pagination?.count || 0}
            tableComponent={
              <Styled.TableContainer>
                <VenuesTable data={data} sortOption={sortOption} sortHandler={sortHandler} />
                <TableSizeControls
                  itemsPerPageProps={{
                    handlePerPageChange,
                    value: currentPerPage,
                  }}
                  pageCountProps={{
                    value: currentPage,
                    pages: pagination?.pages || 1,
                    handlePageChange,
                  }}
                  paginationInfoProps={{
                    total: pagination?.count || 0,
                    currentPage,
                    perPage: currentPerPage,
                  }}
                  paginationNavProps={{
                    currentPage,
                    handlePageChange,
                    pages: pagination?.pages || 1,
                    titleTable: 'Enforcements',
                  }}
                />
              </Styled.TableContainer>
            }
            onMapViewFeatureSelected={onMapViewFeatureSelected}
            buttonObj={buttonObj}
            mapComponent={
              <VenuesMapView
                onMapViewPopUpButtonClicked={buttonObj}
                onMapViewFeatureSelected={onMapViewFeatureSelected}
              />
            }
          />
        </Styled.Wrapper>

        <FilterModal
          open={modalType === MODAL_NAMES.FILTER_MODAL}
          menu={FILTER_DATA}
          filterMapping={filterMapping}
          filterType={FILTER_TYPES.VENUES}
          onClose={closeModal}
          getApi={loadVenues}
          setCurrentPage={setCurrentPage}
        />
      </PageContainer>
    </>
  );
};

export default VenueList;
