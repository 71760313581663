import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useIsReadyAndOffline } from 'hooks';
import { SetQueryParams } from 'utils/old/setQueryParams';
import queryString from 'query-string';
import { Tabs, CollectTable, HasAccess } from 'modules';
import { CollectEntity, AuthEntity } from '_entities';
import { PERMISSIONS, USER_ROLE_TYPES } from '_constants';
import Filter from 'ui-kit/old/filter';
import { isValidDate } from 'utils/old/checkSearchDate';
import { isEmpty } from 'lodash';
import processQSParams from 'utils/old/processQSParams';
import getAssignmentsColumns from '../../constants/OpenAssignments/getAssignmentsColumns';
import {
  INITIAL_COLUMNS as assignmentsInitialColumns,
  COLUMNS as assignmentsColumns,
  sortOptions as assignmentsSortOptions,
} from '../../constants/OpenAssignments/constants';
import getRevisitsColumns from '../../constants/RevisitsNeeds/getRevisitsColumns';
import {
  INITIAL_COLUMNS as revisitsInitialColumns,
  COLUMNS as revisitsColumns,
  sortOptions as revisitsSortOptions,
} from '../../constants/RevisitsNeeds/constants';
import * as Styled from './styles';

const { getAssignmentsList, getRevisitsList } = CollectEntity.actions;
const { getAssignments, getRevisits } = CollectEntity.selectors;
const { getTimeZone } = AuthEntity.selectors;

const { ACCOUNT_MANAGER, MULTI_ACCOUNT_MANAGER, AGENCY_MANAGER, CAMPAIGN_MANAGER, DATA_COLLECTOR } = USER_ROLE_TYPES;

function RetailerList({ setLoading }) {
  const { pagination: revisitsPagination } = useSelector(getRevisits);
  const { pagination: assignmentsPagination } = useSelector(getAssignments);
  const [activeTab, setActiveTab] = useState(null);
  const location = useLocation();
  const { page, page_size: perPage, search: querySearch } = queryString.parse(location.search);
  const [searchValue, setSearchValue] = useState(querySearch || '');
  const history = useHistory();
  const timeZone = useSelector(getTimeZone);
  const dispatch = useDispatch();
  const roleRuleSet = {
    secGeogs: [AGENCY_MANAGER, CAMPAIGN_MANAGER, DATA_COLLECTOR],
    all: [ACCOUNT_MANAGER, MULTI_ACCOUNT_MANAGER],
  };
  const hasAccessToRevisitTable = HasAccess(PERMISSIONS.VIEW_REVISIT_TABLE, roleRuleSet);
  const isReadyAndOffline = useIsReadyAndOffline();
  const TITLES_OBJ = useMemo(() => {
    const titlesArray = [
      {
        title: 'Open assignments',
        id: 'assignment',
        component: (
          <CollectTable
            setLoading={setLoading}
            loadAPICall={getAssignmentsList}
            selector={getAssignments}
            getInitialColumns={getAssignmentsColumns}
            sortOptions={assignmentsSortOptions}
            initialColumns={assignmentsInitialColumns}
            columns={assignmentsColumns}
            search={searchValue}
            showArchived
          />
        ),
      },
    ];
    if (!isReadyAndOffline && hasAccessToRevisitTable) {
      return [
        ...titlesArray,
        {
          title: 'Revisits needed',
          id: 'revisit',
          component: (
            <CollectTable
              setLoading={setLoading}
              loadAPICall={getRevisitsList}
              selector={getRevisits}
              getInitialColumns={getRevisitsColumns}
              sortOptions={revisitsSortOptions}
              initialColumns={revisitsInitialColumns}
              columns={revisitsColumns}
              search={searchValue}
              showArchived
            />
          ),
        },
      ];
    }
    return titlesArray;
  }, [isReadyAndOffline, setLoading, searchValue]);

  const loadData = useCallback(
    async ({ search = searchValue, startPage } = {}) => {
      SetQueryParams({
        queryString: {
          page: startPage || page,
          page_size: perPage,
          search: search || null,
        },
        history,
      });
      try {
        const assignmentsParams = processQSParams({
          page: startPage || page,
          perPage,
          search,
          not_archived: true,
          sortBy: assignmentsSortOptions.sortBy,
          sortOrder: assignmentsSortOptions.sortOrder,
        });

        const revisitsParams = processQSParams({
          page: startPage || page,
          perPage,
          search,
          not_archived: true,
          sortBy: revisitsSortOptions.sortBy,
          sortOrder: revisitsSortOptions.sortOrder,
        });
        await Promise.all([
          dispatch(getAssignmentsList(assignmentsParams)),
          dispatch(getRevisitsList(revisitsParams, timeZone)),
        ]);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    },
    [searchValue],
  );

  const amountCount = useMemo(() => {
    switch (activeTab?.id) {
      case 'revisit': {
        return revisitsPagination?.count || 0;
      }
      case 'assignment': {
        return assignmentsPagination?.count || 0;
      }
      default:
        return 1;
    }
  }, [activeTab, revisitsPagination, assignmentsPagination]);

  const customChangeTabs = (tab) => {
    setActiveTab(() => tab);
  };

  const handleOnChangeInputSearch = (searchVal) => {
    if (isEmpty(searchVal)) {
      setSearchValue('');
    }
    setSearchValue(searchVal);
  };

  const handleClickInput = () => {
    if (isEmpty(searchValue)) return;
    const searchString = isValidDate(searchValue);
    loadData({ search: searchString, startPage: 1 });
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      const searchString = isValidDate(searchValue);
      loadData({ search: searchString, startPage: 1 });
    }
  };

  const handleRemoveSearch = () => {
    if (isEmpty(searchValue)) return;
    loadData({ search: '', startPage: 1 }, location.search);
    setSearchValue('');
  };

  return (
    <Styled.Wrapper>
      <Styled.FilterWrapper className="table-filters">
        <Filter
          type="null"
          placeholder="Search"
          onChangeInput={handleOnChangeInputSearch}
          handleClickInput={handleClickInput}
          onKeyDown={handleKeyDown}
          onRemoveSearch={handleRemoveSearch}
          searchValue={searchValue}
          isWidth
        />
      </Styled.FilterWrapper>
      <Tabs
        hasTabs
        activeTab={activeTab}
        onChangeTabs={customChangeTabs}
        tabOptions={TITLES_OBJ}
        amount={amountCount}
      />
    </Styled.Wrapper>
  );
}

RetailerList.propTypes = {
  setLoading: PropTypes.func.isRequired,
};

export default RetailerList;
