/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable */
import React, { memo, useMemo, useState } from 'react';
import { compact, isNull, uniqueId } from 'lodash';
import LinkCell from 'modules/old/cells/LinkCell';
import HeaderCell from 'modules/old/cells/HeaderCell';
import TextCell from 'modules/old/cells/TextCell';
import { ActionCell } from 'ui-kit';
import { COLUMNS, INITIAL_COLUMNS } from './constants';
import { TableWrapper } from 'ui-kit';
import useSortTable from 'utils/old/useSortTable';
import { getRetailerDataPure } from '_entities/old/RetailerView/retailer.service';
import getToastLock from 'utils/old/getAddToastLock';
import useToastContext from 'utils/old/useToastContext';
import { URLS, PERMISSIONS } from '_constants';
import { IsMassAccount } from 'modules';

const getInitialColumns = ({ handleSort, sortOption, dumpedActiveColumns, editCellParams, getToast, isMassAccount }) =>
  compact([
    dumpedActiveColumns.name && {
      Header: () => (
        <HeaderCell col="Retailer name" sortBy="name" sortOption={sortOption} handleSort={handleSort('name')} />
      ),
      minWidth: 300,
      accessor: 'name',
      Cell: ({ value, original }) => <LinkCell id={uniqueId()} text={value} to={`/retailers/${original.id}`} />,
    },
    dumpedActiveColumns.address && {
      Header: () => (
        <HeaderCell col="Address" sortBy="address" sortOption={sortOption} handleSort={handleSort('address')} />
      ),
      minWidth: 260,
      accessor: 'address',
      Cell: ({ value }) => <TextCell text={value} />,
    },
    dumpedActiveColumns.city && {
      Header: () => <HeaderCell col="City" sortBy="city" sortOption={sortOption} handleSort={handleSort('city')} />,
      minWidth: 260,
      accessor: 'city',
      Cell: ({ value }) => <TextCell text={value} />,
    },
    dumpedActiveColumns.zipcode && {
      Header: () => (
        <HeaderCell col="Zip code" sortBy="zipcode" sortOption={sortOption} handleSort={handleSort('zipcode')} />
      ),
      minWidth: 150,
      accessor: 'zipcode',
      Cell: ({ value }) => <TextCell text={value} />,
    },
    dumpedActiveColumns.zipcode &&
      isMassAccount && {
        Header: () => (
          <HeaderCell
            col="Violation status"
            sortBy="violation_status"
            sortOption={sortOption}
            handleSort={handleSort('violation_status')}
          />
        ),
        minWidth: 150,
        accessor: 'violation',
        Cell: ({ value }) => <TextCell text={value} />,
      },
    dumpedActiveColumns.county_name && {
      Header: () => (
        <HeaderCell col="County" sortBy="county_name" sortOption={sortOption} handleSort={handleSort('county_name')} />
      ),
      minWidth: 150,
      accessor: 'county_name',
      Cell: ({ value }) => <TextCell text={value} />,
    },
    dumpedActiveColumns.phone && {
      Header: () => <HeaderCell col="Phone" sortBy="phone" sortOption={sortOption} handleSort={handleSort('phone')} />,
      minWidth: 150,
      accessor: 'phone',
      Cell: ({ value }) => <TextCell text={value} />,
    },
    dumpedActiveColumns.cdph_business_type && {
      Header: () => (
        <HeaderCell
          col="Retailer Type"
          sortBy="cdph_business_type"
          sortOption={sortOption}
          handleSort={handleSort('cdph_business_type')}
        />
      ),
      minWidth: 150,
      accessor: 'cdph_business_type',
      Cell: ({ value }) => <TextCell text={value} />,
    },
    dumpedActiveColumns.age_restricted_flag && {
      Header: () => (
        <HeaderCell
          col="Age Restriction"
          sortBy="age_restricted_flag"
          sortOption={sortOption}
          handleSort={handleSort('age_restricted_flag')}
        />
      ),
      minWidth: 150,
      accessor: 'age_restricted_flag',
      Cell: ({ value }) => <TextCell text={value} />,
    },
    dumpedActiveColumns.closed_date && {
      Header: () => (
        <HeaderCell
          col="Closed Date"
          sortBy="closed_date"
          sortOption={sortOption}
          handleSort={handleSort('closed_date')}
        />
      ),
      minWidth: 150,
      accessor: 'closed_date',
      Cell: ({ value }) => <TextCell text={value} />,
    },
    dumpedActiveColumns.tobacco && {
      Header: () => (
        <HeaderCell col="Sells Tobacco" sortBy="tobacco" sortOption={sortOption} handleSort={handleSort('tobacco')} />
      ),
      minWidth: 150,
      accessor: 'tobacco',
      Cell: ({ value }) => <TextCell text={isNull(value) ? 'Unknown' : value ? 'Yes' : 'No'} />,
    },
    dumpedActiveColumns.pharmacy && {
      Header: () => (
        <HeaderCell
          col="Has Pharmacy Counter"
          sortBy="pharmacy"
          sortOption={sortOption}
          handleSort={handleSort('pharmacy')}
        />
      ),
      minWidth: 150,
      accessor: 'pharmacy',
      Cell: ({ value }) => <TextCell text={isNull(value) ? 'Unknown' : value ? 'Yes' : 'No'} />,
    },
    {
      Header: () => {},
      width: 112,
      accessor: 'editPen',
      Cell: ({ original }) => <ActionCell original={original} getToast={getToast} {...editCellParams} />,
    },
  ]);

const RetailersTable = ({ data, sortHandler, sortOption, setOpenSurveyModal }) => {
  const handleSort = useSortTable(sortOption, sortHandler);
  const [activeColumns, setActiveColumns] = useState(INITIAL_COLUMNS);
  const [isOpen, setIsOpen] = useState(false);
  const addToast = useToastContext();
  const isMassAccount = IsMassAccount();

  const getToast = () => getToastLock('Retailer', addToast);

  const dumpedActiveColumns = useMemo(
    () => activeColumns.reduce((acc, value) => ({ ...acc, [value]: value }), {}),
    [activeColumns],
  );

  const tableControlParams = useMemo(
    () => ({
      activeColumns,
      columns: COLUMNS,
      setActiveColumns,
      setIsOpen,
      isOpen,
    }),
    [activeColumns, isOpen],
  );

  const editCellParams = useMemo(
    () => ({
      editText: 'retailer',
      getItemDetails: getRetailerDataPure,
      route: URLS.retailersPages.edit,
      getToast,
      setOpenSurveyModal,
    }),
    [],
  );

  const wrappedColumns = useMemo(
    () =>
      getInitialColumns({
        handleSort,
        sortOption,
        dumpedActiveColumns,
        tableControlParams,
        editCellParams,
        getToast,
        isMassAccount,
      }),
    [dumpedActiveColumns, sortOption, tableControlParams, handleSort],
  );

  return (
    <TableWrapper
      columns={wrappedColumns}
      data={data || []}
      tableControlParams={tableControlParams}
      isEdit
      editCellParams={editCellParams}
    />
  );
};

RetailersTable.defaultProps = {};

export default memo(RetailersTable);
