import React from 'react';
import PropTypes from 'prop-types';
import { BUTTON_TYPES } from '_constants';
import { Button } from 'ui-kit';
import { SYNC, REMOVE_CIRCLE } from 'assets/icons';
import * as Styled from './styles';

function SurveyList({ data, showSyncButton, handleSendSurvey, handleDeleteSurvey }) {
  return (
    <Styled.Wrapper data-html2canvas-ignore>
      <Styled.Title data-html2canvas-ignore>Surveys saved offline</Styled.Title>
      {data.length ? (
        <Styled.List>
          {data.map(({ text, data: surveyData }) => (
            <Styled.Item key={text}>
              <Styled.TextWrapper>
                <Styled.Text data-html2canvas-ignore>{text}</Styled.Text>
              </Styled.TextWrapper>
              <Button
                data-html2canvas-ignore
                icon={REMOVE_CIRCLE}
                variant={BUTTON_TYPES.DARK}
                text="Delete"
                onClick={() => handleDeleteSurvey(text)}
              />
              {showSyncButton && (
                <Button
                  icon={SYNC}
                  variant={BUTTON_TYPES.DANGER}
                  text="Sync"
                  onClick={() => handleSendSurvey(text, surveyData)}
                />
              )}
            </Styled.Item>
          ))}
        </Styled.List>
      ) : (
        <Styled.Info data-html2canvas-ignore>You don’t have any surveys that were saved offline</Styled.Info>
      )}
    </Styled.Wrapper>
  );
}

SurveyList.propTypes = {
  data: PropTypes.array.isRequired,
  showSyncButton: PropTypes.bool,
  handleSendSurvey: PropTypes.func.isRequired,
  handleDeleteSurvey: PropTypes.func.isRequired,
};

SurveyList.defaultProps = {
  showSyncButton: false,
};

export default SurveyList;
