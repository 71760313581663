/* eslint-disable react/prop-types */
import React from 'react';
import { compact, uniqueId } from 'lodash';
import LinkCell from 'modules/old/cells/LinkCell';
import HeaderCell from 'modules/old/cells/HeaderCell';
import TextCell from 'modules/old/cells/TextCell';
import { ActionCell } from 'ui-kit';
import { URLS } from '_constants';

const getCampaignColumns = ({
  handleSort,
  sortOption,
  dumpedActiveColumns,
  editCellParams,
  getToast,
  showArchived,
  hasAccess,
  handleCheckbox,
  checkboxList,
}) => {
  return compact([
    showArchived !== null &&
      hasAccess && {
        Header: () => {},
        width: 60,
        accessor: 'archive',
        Cell: ({ original }) => {
          const { id } = original?.campaign;
          return (
            <ActionCell
              original={original}
              name={id}
              value={checkboxList[id] || false}
              handleCheckbox={handleCheckbox}
              isCheckBox
              {...editCellParams}
            />
          );
        },
      },
    dumpedActiveColumns.campaign_name && {
      Header: () => (
        <HeaderCell
          col="Campaign name"
          sortBy="campaign_name"
          sortOption={sortOption}
          handleSort={handleSort('campaign_name')}
        />
      ),
      minWidth: 290,
      accessor: 'campaign',
      Cell: ({ value: { name, id } }) => <LinkCell id={uniqueId()} text={name} to={`${URLS.campaign}/${id}`} />,
    },
    showArchived === null && {
      Header: () => (
        <HeaderCell col="Archived" sortBy="archived" sortOption={sortOption} handleSort={handleSort('archived')} />
      ),
      width: 160,
      accessor: 'archive',
      Cell: ({ original: { isArchived } }) => {
        return <TextCell text={isArchived ? 'Yes' : ' '} />;
      },
    },
    dumpedActiveColumns.assignments && {
      Header: () => (
        <HeaderCell
          col="Assignments"
          sortBy="count_assignments"
          sortOption={sortOption}
          handleSort={handleSort('count_assignments')}
        />
      ),
      minWidth: 140,
      accessor: 'assignments.total',
      Cell: ({ value }) => <TextCell text={`${value}`} />,
    },
    dumpedActiveColumns.assignments_completed && {
      Header: () => (
        <HeaderCell
          col="Assignments completed"
          sortBy="completed_assignments"
          sortOption={sortOption}
          handleSort={handleSort('completed_assignments')}
        />
      ),
      minWidth: 200,
      accessor: 'assignments',
      Cell: ({ value: { total, completed } }) => {
        const percent = total > 0 ? ((completed / total) * 100).toFixed(2) : 0;
        const percentString = total > 0 ? `(${percent}%)` : '';
        return <TextCell text={`${completed} ${percentString}`} />;
      },
    },
    dumpedActiveColumns.agency && {
      Header: () => (
        <HeaderCell
          col="Agency"
          sortBy="agency__name"
          sortOption={sortOption}
          handleSort={handleSort('agency__name')}
        />
      ),
      minWidth: 150,
      accessor: 'agency',
      Cell: ({ value }) => <TextCell text={value} />,
    },
    dumpedActiveColumns.manager && {
      Header: () => (
        <HeaderCell
          col="Campaign manager"
          sortBy="campaign_manager__first_name"
          sortOption={sortOption}
          handleSort={handleSort('campaign_manager__first_name')}
        />
      ),
      minWidth: 150,
      accessor: 'manager',
      Cell: ({ value: { name, id } }) => {
        return <LinkCell id={uniqueId()} text={name} to={`${URLS.users}/${id}`} />;
      },
    },
    dumpedActiveColumns.secondary_geography && {
      Header: () => (
        <HeaderCell
          col="Secondary geography"
          sortBy="sec_geog_name"
          sortOption={sortOption}
          handleSort={handleSort('sec_geog_name')}
        />
      ),
      minWidth: 150,
      accessor: 'sec_geog',
      Cell: ({ value }) => <TextCell text={value || 'All'} />,
    },
    dumpedActiveColumns.form && {
      Header: () => (
        <HeaderCell col="Form" sortBy="form__name" sortOption={sortOption} handleSort={handleSort('form__name')} />
      ),
      minWidth: 250,
      accessor: 'form',
      Cell: ({ value }) => <TextCell text={value} />,
    },
    {
      Header: () => {},
      width: 60,
      accessor: 'editPen',
      Cell: ({ original }) => <ActionCell onlyEdit original={original} getToast={getToast} {...editCellParams} />,
    },
  ]);
};
export default getCampaignColumns;
