import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { PageContainer, EntityHeader, IsMassAccount } from 'modules';
import { Loader } from 'ui-kit';
import { VisitEntity } from '_entities';

import { URLS } from '_constants';
import { PAGE_TYPE } from './constants';
import VisitViewDetails from './components/VisitViewDetails';
import * as Styled from './styles';

const { getVisitInfo, setVisitInfo, resetVisitData, resetViolationData, getViolationInfo } = VisitEntity.actions;
const { getVisitData } = VisitEntity.selectors;

function VisitView(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { data: visitData } = useSelector(getVisitData);

  const [isLoading, setIsLoading] = useState(true);
  const isMassAccount = IsMassAccount();
  const visitAttributes = visitData && visitData.attributes;
  const hasViolationInfo = visitAttributes && visitAttributes?.is_violation_open_status !== null;

  const updateVisitInfo = useCallback(async () => {
    try {
      setIsLoading(true);
      const payload = { ...props };
      await Promise.all([dispatch(getVisitInfo(id)), dispatch(setVisitInfo(payload))]);
    } catch (err) {
      console.log(err);
      history.push(URLS.notFound);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    updateVisitInfo();
    return async () => {
      // Prevent show data from previous visit/violation after failed request
      await Promise.all([dispatch(resetVisitData()), dispatch(resetViolationData())]);
    };
  }, []);

  useEffect(() => {
    if (!isMassAccount || !hasViolationInfo) return;
    (async () => {
      setIsLoading(true);
      try {
        await dispatch(getViolationInfo(id));
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [hasViolationInfo]);

  if (isLoading) return <Loader />;

  return (
    <PageContainer>
      <Styled.Wrapper>
        <EntityHeader pageType={PAGE_TYPE.VIEW} updateVisitInfo={updateVisitInfo} />
        <VisitViewDetails />
      </Styled.Wrapper>
    </PageContainer>
  );
}

export default VisitView;
