/* eslint-disable react/prop-types */
import React, { memo, useMemo, useState } from 'react';
import { compact, uniqueId } from 'lodash';
import LinkCell from 'modules/old/cells/LinkCell';
import HeaderCell from 'modules/old/cells/HeaderCell';
import TextCell from 'modules/old/cells/TextCell';
import { TableWrapper } from 'ui-kit';
import useSortTable from 'utils/old/useSortTable';
import getToastLock from 'utils/old/getAddToastLock';
import useToastContext from 'utils/old/useToastContext';
import { getVisitLinkText } from 'utils/custom';
import { URLS, VISIT_TYPE_LINKS } from '_constants';
import { COLUMNS, INITIAL_COLUMNS } from './constants';

const getInitialColumns = ({ handleSort, sortOption, dumpedActiveColumns, userId }) =>
  compact([
    dumpedActiveColumns.retailer__name && {
      Header: () => (
        <HeaderCell
          col="Retailer name"
          sortBy="retailer__name"
          sortOption={sortOption}
          handleSort={handleSort('retailer__name')}
        />
      ),
      minWidth: 260,
      accessor: 'retailer__name',
      Cell: ({ value, original }) => {
        const usersIdList = original?.usersList?.map(({ id }) => id) || [];
        const retailerLinkText = original?.closedDate ? `${value} (closed: ${original?.closedDate})` : value;

        return original?.url &&
          usersIdList.includes(userId) &&
          original?.status !== 1 &&
          original?.closedDate === null ? (
          <LinkCell id={uniqueId()} text={value} to={`${URLS.survey}/${original.url}`} />
        ) : (
          <LinkCell id={uniqueId()} text={retailerLinkText} to={`${URLS.retailers}/${original.retailerID}`} />
        );
      },
    },
    dumpedActiveColumns.retailer__address && {
      Header: () => (
        <HeaderCell
          col="Address"
          sortBy="retailer__address"
          sortOption={sortOption}
          handleSort={handleSort('retailer__address')}
        />
      ),
      minWidth: 150,
      accessor: 'address',
      Cell: ({ value }) => <TextCell text={value} />,
    },
    dumpedActiveColumns.retailer__city && {
      Header: () => (
        <HeaderCell
          col="City"
          sortBy="retailer__city"
          sortOption={sortOption}
          handleSort={handleSort('retailer__city')}
        />
      ),
      minWidth: 150,
      accessor: 'city',
      Cell: ({ value }) => <TextCell text={value} />,
    },
    dumpedActiveColumns.retailer__zipcode && {
      Header: () => (
        <HeaderCell
          col="Zip code"
          sortBy="retailer__zipcode"
          sortOption={sortOption}
          handleSort={handleSort('retailer__zipcode')}
        />
      ),
      minWidth: 150,
      accessor: 'zipcode',
      Cell: ({ value }) => <TextCell text={value} />,
    },
    dumpedActiveColumns.usersList && {
      Header: () => (
        <HeaderCell
          isHideSorting
          col="Data Collector(s)"
          sortBy="user"
          sortOption={sortOption}
          handleSort={handleSort('user')}
        />
      ),
      minWidth: 260,
      accessor: 'usersList',
      Cell: ({ value }) => {
        return (
          <div style={{ margin: 'auto 0' }}>
            {value.map((elem) => {
              const fullName = `${elem.attributes.first_name} ${elem.attributes.last_name}`;
              const linkText = fullName !== ' ' ? fullName : elem.attributes.email;
              return <LinkCell id={uniqueId()} text={linkText} to={`${URLS.users}/${elem.id}`} />;
            })}
          </div>
        );
      },
    },
    dumpedActiveColumns.hasViolation && {
      Header: () => <HeaderCell isHideSorting col="Has violation" />,
      width: 120,
      accessor: 'visit_violation',
      Cell: ({ value }) => <TextCell text={value ? 'Yes' : ' '} />,
    },
    dumpedActiveColumns.visits && {
      Header: () => <HeaderCell isHideSorting col="Visit(s)" hasPopUp sortBy="visitId" />,
      width: 260,
      accessor: 'visitId',
      Cell: ({ original }) => {
        const { visitText, violationText } = getVisitLinkText(
          original?.status,
          original?.visitId,
          original?.revisitNeeded,
          original?.visit_violation,
        );

        return (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            {visitText && (
              <LinkCell
                id={uniqueId()}
                text={visitText}
                to={`${VISIT_TYPE_LINKS[original?.visitType]}/${original?.visitId}`}
              />
            )}
            {violationText && (
              <LinkCell
                id={uniqueId()}
                text={violationText}
                to={`${VISIT_TYPE_LINKS[original?.visitType]}/${original?.revisitNeeded}`}
              />
            )}
          </div>
        );
      },
    },
    dumpedActiveColumns.status && {
      Header: () => (
        <HeaderCell
          isHideSorting
          col="Status"
          sortBy="status_order"
          sortOption={sortOption}
          handleSort={handleSort('status_order')}
        />
      ),
      minWidth: 150,
      accessor: 'status',
      Cell: ({ value }) => {
        const statusLabelMapper = {
          0: 'Not started',
          1: 'Completed',
          2: 'In progress',
        };
        return <TextCell padding text={statusLabelMapper[value]} centerContent />;
      },
    },
  ]);

const AssignmentsTable = ({ data, sortHandler, sortOption, userId }) => {
  const handleSort = useSortTable(sortOption, sortHandler);
  const [activeColumns, setActiveColumns] = useState(INITIAL_COLUMNS);
  const [isOpen, setIsOpen] = useState(false);
  const addToast = useToastContext();

  const getToast = () => getToastLock('Assignments', addToast);

  const dumpedActiveColumns = useMemo(
    () => activeColumns.reduce((acc, value) => ({ ...acc, [value]: value }), {}),
    [activeColumns],
  );

  const tableControlParams = useMemo(
    () => ({
      activeColumns,
      columns: COLUMNS,
      setActiveColumns,
      setIsOpen,
      isOpen,
    }),
    [activeColumns, isOpen],
  );

  const editCellParams = useMemo(
    () => ({
      editText: 'youth',
      route: URLS.youthPages.edit,
      getToast,
    }),
    [],
  );

  const wrappedColumns = useMemo(
    () =>
      getInitialColumns({
        handleSort,
        sortOption,
        dumpedActiveColumns,
        tableControlParams,
        editCellParams,
        getToast, // eslint-disable-next-line
        userId,
      }),
    [dumpedActiveColumns, sortOption, tableControlParams, handleSort],
  );

  return (
    <TableWrapper
      columns={wrappedColumns}
      data={data || []}
      tableControlParams={tableControlParams}
      isEdit
      editCellParams={editCellParams}
    />
  );
};

export default memo(AssignmentsTable);
