export const GET_VISIT_INFO = 'GET_VISIT_INFO';
export const GET_VISIT_INFO_SUCCESS = 'GET_VISIT_INFO_SUCCESS';
export const GET_VISIT_INFO_FAIL = 'GET_VISIT_INFO_FAIL';

export const SEND_VISIT_VIOLATION = 'SEND_VISIT_VIOLATION';
export const SEND_VISIT_VIOLATION_SUCCESS = 'SEND_VISIT_VIOLATION_SUCCESS';
export const SEND_VISIT_VIOLATION_FAIL = 'SEND_VISIT_VIOLATION_FAIL';

export const GET_VIOLATION_INFO = 'GET_VIOLATION_INFO';
export const GET_VIOLATION_INFO_SUCCESS = 'GET_VIOLATION_INFO_SUCCESS';
export const GET_VIOLATION_INFO_FAIL = 'GET_VIOLATION_INFO_FAIL';

export const UPDATE_VISIT = 'UPDATE_VISIT';
export const UPDATE_VISIT_SUCCESS = 'UPDATE_VISIT_SUCCESS';
export const UPDATE_VISIT_FAIL = 'UPDATE_VISIT_FAIL';

export const ENFORCEMENT_TABLE_DATA = 'ENFORCEMENT_TABLE_DATA';

export const ADD_VISIT = 'ADD_VISIT';
export const ADD_VISIT_SUCCESS = 'ADD_VISIT_SUCCESS';
export const ADD_VISIT_FAIL = 'ADD_VISIT_FAIL';

export const UPDATE_VIOLATION = 'UPDATE_VIOLATION';
export const UPDATE_VIOLATION_SUCCESS = 'UPDATE_VIOLATION_SUCCESS';
export const UPDATE_VIOLATION_FAIL = 'UPDATE_VIOLATION_FAIL';

export const GET_VISIT_ASSESSMENT_LIST = 'GET_VISIT_ASSESSMENT_LIST';
export const GET_VISIT_ASSESSMENT_LIST_SUCCESS = 'GET_VISIT_ASSESSMENT_LIST_SUCCESS';
export const GET_VISIT_ASSESSMENT_LIST_FAIL = 'GET_VISIT_ASSESSMENT_LIST_FAIL';

export const GET_VISIT_EDUCATION_LIST = 'GET_VISIT_EDUCATION_LIST';
export const GET_VISIT_EDUCATION_LIST_SUCCESS = 'GET_VISIT_EDUCATION_LIST_SUCCESS';
export const GET_VISIT_EDUCATION_LIST_FAIL = 'GET_VISIT_EDUCATION_LIST_FAIL';

export const SET_VISIT_INFO = 'SET_VISIT_INFO';

export const GET_VISIT_LIST = 'GET_VISIT_LIST';
export const GET_VISIT_LIST_SUCCESS = 'GET_VISIT_LIST_SUCCESS';
export const GET_VISIT_LIST_FAIL = 'GET_VISIT_LIST_FAIL';

export const GET_VISITS_FORMS = 'GET_ENFORCEMENT_VISITS_FORMS';
export const GET_VISITS_FORMS_SUCCESS = 'GET_ENFORCEMENT_VISITS_FORMS_SUCCESS';
export const GET_VISITS_FORMS_FAIL = 'GET_ENFORCEMENT_VISITS_FORMS_FAIL';

export const GET_VISITS_LIST_CSV = 'GET_VISITS_LIST_CSV';
export const GET_VISITS_LIST_CSV_SUCCESS = 'GET_VISITS_LIST_CSV_SUCCESS';
export const GET_VISITS_LIST_CSV_FAIL = 'GET_VISITS_LIST_CSV_FAIL';

export const RESET_VISIT_DATA = 'RESET_VISIT_DATA';
export const RESET_VIOLATION_INFO = 'RESET_VIOLATION_INFO';

export const GET_ENFOCEMENT_VIOLATION = 'GET_ENFOCEMENT_VIOLATION';
export const GET_ENFOCEMENT_VIOLATION_SUCCESS = 'GET_ENFOCEMENT_VIOLATION_SUCCESS';
export const GET_ENFOCEMENT_VIOLATION_FAIL = 'GET_ENFOCEMENT_VIOLATION_FAIL';

export const GET_EDUCATION_VIOLATION = 'GET_EDUCATION_VIOLATION';
export const GET_EDUCATION_VIOLATION_SUCCESS = 'GET_EDUCATION_VIOLATION_SUCCESS';
export const GET_EDUCATION_VIOLATION_FAIL = 'GET_EDUCATION_VIOLATION_FAIL';
