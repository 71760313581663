export const GET_USER_LIST = 'GET_USER_LIST';
export const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS';
export const GET_USER_LIST_FAIL = 'GET_USER_LIST_FAIL';

export const SET_USERS = 'SET_USERS';
export const SET_USERS_SUCCESS = 'SET_USERS_SUCCESS';
export const SET_USERS_FAIL = 'SET_USERS_FAIL';

export const GET_USER_ROLE = 'GET_USER_ROLE';
export const GET_USER_ROLE_SUCCESS = 'GET_USER_ROLE_SUCCESS';
export const GET_USER_ROLE_FAIL = 'GET_USER_ROLE_FAIL';

export const GET_USER_AGENCIES = 'GET_USER_AGENCIES';
export const GET_USER_AGENCIES_SUCCESS = 'GET_USER_AGENCIES_SUCCESS';
export const GET_USER_AGENCIES_FAIL = 'GET_USER_AGENCIES_FAIL';

export const GET_SEC_GEO = 'GET_SEC_GEO';
export const GET_SEC_GEO_SUCCESS = 'GET_SEC_GEO_SUCCESS';
export const GET_SEC_GEO_FAIL = 'GET_SEC_GEO_FAIL';

export const GET_USER_ACCOUNTS = 'GET_USER_ACCOUNTS';
export const GET_USER_ACCOUNTS_SUCCESS = 'GET_USER_ACCOUNTS_SUCCESS';
export const GET_USER_ACCOUNTS_FAIL = 'GET_USER_ACCOUNTS_FAIL';

export const ADD_USER_ACCOUNT = 'ADD_USER_ACCOUNT';
export const ADD_USER_ACCOUNT_SUCCESS = 'ADD_USER_ACCOUNT_SUCCESS';
export const ADD_USER_ACCOUNT_FAIL = 'ADD_USER_ACCOUNT_FAIL';

export const EDIT_USER_INFO = 'EDIT_USER_INFO';
export const EDIT_USER_INFO_SUCCESS = 'EDIT_USER_INFO_SUCCESS';
export const EDIT_USER_INFO_FAIL = 'EDIT_USER_INFO_FAIL';

export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_FAIL = 'GET_USER_INFO_FAIL';

export const RESET_USER_INFO = 'RESET_USER_INFO';

export const GET_USERS_CSV = 'GET_USERS_CSV';
export const GET_USERS_CSV_SUCCESS = 'GET_USERS_CSV_SUCCESS';
export const GET_USERS_CSV_FAIL = 'GET_USERS_CSV_FAIL';
