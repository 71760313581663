import React from 'react';
import { toast, Slide, Zoom } from 'react-toastify';
import { SUCCESS_ICON, WARNING_ICON, ERROR_ICON } from 'assets/icons/notifications';
import { Img } from 'ui-kit';
import { NOTIFICATION_TYPES } from '_constants';
import './styles.css';

const DEFAULT_NOTIFICATION = {
  transition: Slide,
  position: 'bottom-center',
  draggable: false,
  hideProgressBar: true,
  autoClose: 3000,
  closeOnClick: true,
};

const INFO_NOTIFICATION = {
  transition: Zoom,
  position: 'top-right',
  draggable: false,
  hideProgressBar: true,
  autoClose: 3000,
  closeOnClick: true,
  className: 'notification_bell_messages',
};

const getNotificationPayload = (type) => {
  switch (type) {
    case NOTIFICATION_TYPES.SUCCESS:
      return {
        heading: 'Success',
        defaultIcon: SUCCESS_ICON,
      };
    case NOTIFICATION_TYPES.WARNING:
      return {
        heading: 'Warning',
        defaultIcon: WARNING_ICON,
      };
    case NOTIFICATION_TYPES.ERROR:
      return {
        heading: 'Error',
        defaultIcon: ERROR_ICON,
      };
    default:
      return {};
  }
};

const notificationBody = (type, text, icon) => {
  const { heading, defaultIcon } = getNotificationPayload(type);

  return (
    <div className="Toastify__toast-content">
      {icon && <Img className="Toastify__toast-icon" size={[20, 20]} src={icon || defaultIcon} />}
      <div className="Toastify__toast-text-wrapper">
        <h3 className="Toastify__toast-text-heading">{heading}</h3>
        <p className="Toastify__toast-text-content">{text}</p>
      </div>
    </div>
  );
};

const info = ({ notification, icon, onClick }) => {
  toast.dismiss();

  return toast.info(
    notificationBody(NOTIFICATION_TYPES.INFO, notification, icon, onClick),
    icon ? DEFAULT_NOTIFICATION : { ...INFO_NOTIFICATION, onClick },
  );
};
const success = (notification, icon) =>
  toast.success(notificationBody(NOTIFICATION_TYPES.SUCCESS, notification, icon), DEFAULT_NOTIFICATION);
const warning = (notification, icon) =>
  toast.warning(notificationBody(NOTIFICATION_TYPES.WARNING, notification, icon), DEFAULT_NOTIFICATION);
const error = (notification, icon, props = {}) =>
  toast.error(notificationBody(NOTIFICATION_TYPES.ERROR, notification, icon), {
    ...DEFAULT_NOTIFICATION,
    ...props,
  });
const custom = (notification, props = {}) =>
  toast(notification, {
    ...DEFAULT_NOTIFICATION,
    autoClose: false,
    ...props,
  });
const removeAllNotifications = () => toast.dismiss();

export default {
  info,
  success,
  warning,
  error,
  custom,
  removeAllNotifications,
  getNotificationPayload,
};
