import styled from 'styled-components';

export const FooterContainer = styled.div`
  height: 52px;
  background-color: var(--soft-grey);
  display: flex;
  align-items: center;
  text-align: center;
`;

export const FooterContent = styled.p`
  width: 100%;
  font-size: 14px;
  color: var(--dark);

  a {
    color: var(--danger);
    text-decoration: underline;
  }
`;
