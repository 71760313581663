import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PageContainer, ExportVisitModal } from 'modules';
import { isEmpty, isNaN } from 'lodash';
import queryString from 'query-string';
import { isValidDate } from 'utils/old/checkSearchDate';
import PropTypes from 'prop-types';
import Filter from 'ui-kit/old/filter';
import { FILTER_TABS } from '_constants/old/filters';
import qs from 'qs';
import FILTER_FIELDS from '_constants/old/_constants/filters/FILTER_FIELDS';
import { newSavePageToPdf } from 'utils/custom';
import { FilterEntity, NewFiltersEntity, VisitEntity, AuthEntity } from '_entities';
import { FilterModal, MapPopup } from 'modules/Modals';
import { ChipList } from 'modules/Modals/old/FilterModal/components/ChipsList';
import { SetQueryParams } from 'utils/old/setQueryParams';
import sortParam from 'utils/old/sortParam';
import ITEMS_PER_PAGE_OPTIONS from '_constants/old/_constants/ITEMS_PER_PAGE_OPTIONS';
import DEFAULT_SORT from '_constants/old/_constants/DEFAULT_SORT';
import { TableSizeControls, Loader, TableTabsWrapper } from 'ui-kit';
import VisitsTable from 'modules/VisitsTable';
import qsSearchApplyUtil from 'utils/old/qsSearchApplyUtil';
import { EXPORT_DATA_OPTIONS_NAMES } from '_constants/old/_constants/EXPORT_DATA';
import ExportDataDropDown from 'ui-kit/old/ExportDataDropDown';
import generateQSParams from 'utils/old/generateQSParams';
import { ENTITY_TYPE } from 'modules/Modals/old/mapPopup/components/constants';
import { PERMISSIONS, PAGE_TYPES } from '_constants';
import isIncludes from 'modules/AccessControl/isIncludes';
import MapView from '../../pages/old/RetailerList/mapView';

import * as Styled from './styles';
import getFilterMappingObj from './Filters/chips';

const { getVisitsUser, getVisitsAgency, getLocationMapping } = NewFiltersEntity.actions;
const { applyFilters, setShadowFilter } = FilterEntity.actions;
const { getVisitFormList } = VisitEntity.actions;
const { getProfilePermissions, getTimeZone } = AuthEntity.selectors;

const FilterData = [
  {
    name: FILTER_TABS.QUICK_QUERY,
    isActive: true,
  },
  {
    name: FILTER_TABS.QUERY_BUILDER,
    isActive: true,
  },
  {
    name: FILTER_TABS.ADVANCED_FILTERS,
    isActive: true,
  },
];

const titles = [
  { value: 'List View', id: 'list' },
  { value: 'Map View', id: 'map' },
];

const MODAL_NAMES = {
  FILTER_MODAL: 'FILTER_MODAL',
};

const exceptionFields = ['page', 'page_size', 'ordering'];

const VisitsLists = ({ tableAPIcall, titleHeader, tableData, visitType, getInitColumns, filterType, search }) => {
  const [activeTab, setActiveTab] = useState(titles[0].id);
  const [exportValue, setExportValue] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [currentPerPage, setCurrentPerPage] = useState(ITEMS_PER_PAGE_OPTIONS[1].value);
  const [currentPage, setCurrentPage] = useState(1);
  const userPermission = useSelector(getProfilePermissions);
  const [sortOption, setSortOption] = useState({
    sortBy: DEFAULT_SORT.retailerViewEnforcements.prop,
    sortOrder: DEFAULT_SORT.retailerViewEnforcements.order,
  });
  const timeZone = useSelector(getTimeZone);
  const [loading, setLoading] = useState(false);

  const [isMapPopup, setOpenMapPopup] = useState(false);
  const [buttonObj, setButtonObj] = useState();
  const [modalType, setModalType] = useState(null);
  const [retailerId, setRetailerId] = useState(null);
  const [isOpenExportVisitModal, setOpenExportVisitModal] = useState(false);
  const [isFormLoad, setIsFormLoad] = useState(false);

  const handleModalType = (type) => setModalType(type);
  const closeModal = () => setModalType(null);

  const onChangeTabs = (titleTab) => {
    const title = titles.find((item) => item.value === titleTab).id;
    setActiveTab(title);
  };

  const { pagination, data } = tableData;

  const {
    oldFilter: { generalFilters, shadowFilters },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    (async function getDataForDrops() {
      try {
        setLoading(true);
        await Promise.all([
          isIncludes(userPermission, PERMISSIONS.CHANGE_USER) && dispatch(getVisitsUser()),
          dispatch(getVisitsAgency(visitType)),
          dispatch(getLocationMapping()),
        ]);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const loadVisits = useCallback(
    async ({
      page = currentPage,
      perPage = currentPerPage,
      sortBy = sortOption.sortBy,
      sortOrder = sortOption.sortOrder,
      filters = generalFilters,
      ...rest
    } = {}) => {
      try {
        setLoading(true);
        await tableAPIcall(
          {
            ...filters,
            ...rest,
            page,
            perPage,
            sortBy,
            sortOrder,
            timeZone,
          },
          dispatch,
        );
        SetQueryParams({
          queryString: {
            ...filters,
            ...rest,
            page,
            page_size: perPage,
            ordering: sortParam({ sortBy, sortOrder }),
          },
          history,
        });
      } catch (e) {
        console.log('e', e);
      } finally {
        setLoading(false);
      }
    },
    [currentPage, dispatch, currentPerPage, SetQueryParams, generalFilters, sortOption],
  );

  const sortHandler = useCallback(
    (sortBy, sortOrder) => {
      setSortOption({ sortBy, sortOrder });
      loadVisits({ sortBy, sortOrder });
    },
    [loadVisits, setSortOption],
  );

  const handlePageChange = useCallback(
    ({ value }) => {
      if (currentPage === value) return;
      setCurrentPage(value);
      loadVisits({ page: value });
    },
    [loadVisits, setCurrentPage],
  );

  const handlePerPageChange = useCallback(
    ({ value }) => {
      if (currentPerPage === value) return;
      setCurrentPerPage(value);
      setCurrentPage(1);
      loadVisits({ perPage: value, page: 1 });
    },
    [setCurrentPerPage, setCurrentPage, loadVisits],
  );

  useEffect(() => {
    const { page, page_size: pageSize, search: searchQuery, ...rest } = queryString.parse(location.search);
    const queryPage = isNaN(+page) || +page === 0 ? currentPage : +page;
    const queryPageSize = isNaN(+pageSize) || +pageSize === 0 ? currentPerPage : +pageSize;
    setCurrentPage(queryPage);
    setCurrentPerPage(queryPageSize);
    setSearchValue(searchQuery || '');

    const newFilters = {
      ...(searchQuery ? { search: searchQuery } : ''),
      ...rest,
    };
    dispatch(applyFilters({ page, page_size: pageSize, ...newFilters }));
    loadVisits({ filters: newFilters, page, perPage: pageSize });
  }, []);

  const onMapViewFeatureSelected = (mapfeature) => {
    if (mapfeature) {
      setRetailerId(mapfeature.id);
      setOpenMapPopup(true);
    } else {
      setOpenMapPopup(false);
    }
  };

  useEffect(() => {
    if (
      shadowFilters &&
      !isEmpty(shadowFilters[FILTER_FIELDS?.VIOLATION_COUNT_GRT]) &&
      !isEmpty(shadowFilters[FILTER_FIELDS?.VIOLATION_COUNT_GRT_DATEPICKER_RANGE]) &&
      shadowFilters[FILTER_FIELDS?.VIOLATION_COUNT_GRT_DATEPICKER_RANGE]?.length > 11
    ) {
      dispatch(
        setShadowFilter({
          ...shadowFilters,
          [FILTER_FIELDS?.VIOLATION_COUNT_GRT_DATA_RANGE]: `${
            shadowFilters[FILTER_FIELDS?.VIOLATION_COUNT_GRT_DATEPICKER_RANGE]
          },${shadowFilters[FILTER_FIELDS?.VIOLATION_COUNT_GRT]}`,
        }),
      );
    } else {
      dispatch(
        setShadowFilter({
          ...shadowFilters,
          [FILTER_FIELDS?.VIOLATION_COUNT_GRT_DATA_RANGE]: null,
          [FILTER_FIELDS?.IS_CURRENT]: null,
        }),
      );
    }
  }, [shadowFilters?.violation_count_grt_datepicker_range, shadowFilters?.violation_count_grt]);

  const handleRemoveSearch = () => {
    if (isEmpty(searchValue)) return;
    qsSearchApplyUtil({
      dispatch,
      getApi: loadVisits,
      generalFilters,
    });
    setSearchValue('');
  };

  const handleOnChangeInputSearch = (searchVal) => {
    if (isEmpty(searchVal)) {
      setSearchValue('');
      qsSearchApplyUtil({
        dispatch,
        getApi: loadVisits,
        generalFilters,
      });
    }
    setSearchValue(searchVal);
  };

  const handleClickInput = () => {
    if (isEmpty(searchValue)) return;
    const searchString = isValidDate(searchValue);
    qsSearchApplyUtil({
      search: searchString,
      dispatch,
      getApi: loadVisits,
      generalFilters,
    });
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      const searchString = isValidDate(searchValue);
      qsSearchApplyUtil({
        search: searchString,
        dispatch,
        getApi: loadVisits,
        generalFilters,
      });
    }
  };

  const handleExportDataChange = ({ value }) => {
    setExportValue(value);
    switch (value) {
      case EXPORT_DATA_OPTIONS_NAMES.EXPORT:
        OpenExportVisitModal();
        // TODO when back will be ready
        // visitsCSV(dispatch, search, (elem) => notification.success(elem));
        break;
      case EXPORT_DATA_OPTIONS_NAMES.PRINT:
        setLoading(true);
        // In order to show loader before html2canvas function start working we should wrap function in setTimeout
        setTimeout(() => newSavePageToPdf({ setExportLoading: setLoading, hasFilters: true }, PAGE_TYPES.TABLE), 0);
        break;
      default:
    }
  };

  const onKeyDownFilter = (e) => {
    if (e.keyCode === 13) {
      handleModalType(MODAL_NAMES.FILTER_MODAL);
      e.preventDefault();
    }
  };

  const openFilterModal = () => {
    handleModalType(MODAL_NAMES.FILTER_MODAL);
  };

  const closeExportVisitModal = () => {
    setOpenExportVisitModal(false);
  };

  const OpenExportVisitModal = () => {
    setOpenExportVisitModal(true);
    (async function getExportVisitForms() {
      try {
        setIsFormLoad(true);
        const newFilters = Object.keys(shadowFilters).reduce((acc, item) => {
          if (exceptionFields.includes(item)) return acc;
          acc[item] = shadowFilters[item];
          return acc;
        }, {});
        const params = generateQSParams(newFilters);
        await dispatch(getVisitFormList(visitType, params));
      } catch (error) {
        console.log(error);
      } finally {
        setIsFormLoad(false);
      }
    })();
  };

  useEffect(() => {
    const searchObj = qs.parse(search.split('?')[1]);
    dispatch(applyFilters(searchObj));
  }, []);

  return (
    <>
      <PageContainer>
        {loading && <Loader />}
        {isMapPopup && (
          <MapPopup
            entityId={retailerId || 221300}
            onClose={setOpenMapPopup}
            setButtonObj={setButtonObj}
            entityType={ENTITY_TYPE.RETAILER}
            allowVisits
          />
        )}
        <Styled.Wrapper>
          <ExportVisitModal
            onClose={closeExportVisitModal}
            open={isOpenExportVisitModal && !isFormLoad}
            visitType={visitType}
          />
          <Styled.Header className="table-header">
            <Styled.H1>{titleHeader}</Styled.H1>
            <Styled.HeaderWrapper>
              {activeTab === titles[0].id && (
                <ExportDataDropDown onChange={handleExportDataChange} value={exportValue} />
              )}
            </Styled.HeaderWrapper>
          </Styled.Header>
          <Styled.FilterWrapper data-html2canvas-ignore>
            <Filter
              type="button"
              placeholder={`Search ${filterType.toLowerCase()}`}
              handleClick={openFilterModal}
              onChangeInput={handleOnChangeInputSearch}
              handleClickInput={handleClickInput}
              onKeyDown={handleKeyDown}
              onKeyDownFilter={onKeyDownFilter}
              onRemoveSearch={handleRemoveSearch}
              searchValue={searchValue}
              isWidth
            />
          </Styled.FilterWrapper>
          <Styled.ChipsWrapper className="table-filters">
            <ChipList
              withoutScroll
              filterMapping={getFilterMappingObj(filterType)}
              getApi={loadVisits}
              setCurrentPage={setCurrentPage}
            />
          </Styled.ChipsWrapper>
          <TableTabsWrapper
            hasTabs
            onChangeTabs={onChangeTabs}
            activeTab={activeTab}
            tabOptions={titles}
            amount={pagination?.count || 0}
            tableComponent={
              <Styled.TableContainer>
                <VisitsTable
                  getInitColumns={getInitColumns}
                  data={data}
                  sortOption={sortOption}
                  sortHandler={sortHandler}
                  visitType={filterType}
                />
                <TableSizeControls
                  itemsPerPageProps={{
                    handlePerPageChange,
                    value: currentPerPage,
                  }}
                  pageCountProps={{
                    value: currentPage,
                    pages: pagination?.pages || 1,
                    handlePageChange,
                  }}
                  paginationInfoProps={{
                    total: pagination?.count || 0,
                    currentPage,
                    perPage: currentPerPage,
                  }}
                  paginationNavProps={{
                    currentPage,
                    handlePageChange,
                    pages: pagination?.pages || 1,
                    titleTable: 'Enforcements',
                  }}
                />
              </Styled.TableContainer>
            }
            onMapViewFeatureSelected={onMapViewFeatureSelected}
            buttonObj={buttonObj}
            mapComponent={
              <MapView onMapViewPopUpButtonClicked={buttonObj} onMapViewFeatureSelected={onMapViewFeatureSelected} />
            }
          />
        </Styled.Wrapper>

        <FilterModal
          open={modalType === MODAL_NAMES.FILTER_MODAL}
          menu={FilterData}
          filterMapping={getFilterMappingObj(filterType)}
          filterType={filterType}
          onClose={closeModal}
          getApi={loadVisits}
          setCurrentPage={setCurrentPage}
        />
      </PageContainer>
    </>
  );
};

VisitsLists.propTypes = {
  tableAPIcall: PropTypes.func.isRequired,
  titleHeader: PropTypes.string.isRequired,
  tableData: PropTypes.object.isRequired,
  visitType: PropTypes.number.isRequired,
  getInitColumns: PropTypes.func.isRequired,
  filterType: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
};

export default VisitsLists;
