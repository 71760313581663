import { createInitialValues } from 'utils/custom';

export const formNames = {
  name: 'name',
  address: 'address',
  address2: 'address2',
  city: 'city',
  state: 'state',
  county: 'county_name',
  ticketsSold: 'tickets_sold',
  gamingMachine: 'gaming_machine',
  zipCode: 'zipcode',
  phone: 'phone_number',
  type: 'store_type',
  chain: 'chain',
  corporation: 'corporation',
  license: 'lic_num',
  other: 'other1',
  other2: 'other2',
  inBusiness: 'is_current',
  closeDate: 'closed_date',
  ageRestriction: 'age_restriction',
  sellTobacco: 'tobacco',
  sellCigarettes: 'cigarettes_sold',
  sellCigars: 'cigars_sold',
  sellEcigarettes: 'ecig_sold',
  sellSmokeless: 'smokeless_sold',
  sellAlcohol: 'alcohol',
  hasPharmacy: 'pharmacy',
  hasAVC: 'has_avc_agreement',
  acceptsWIC: 'wic',
  acceptsSNAP: 'snap',
  internetSales: 'has_internet_sales',
  url: 'url',
  latlong: 'latlong',
};

export const FORM_ACTION_TYPES = {
  ENTER_DATA: 'ENTER_DATA',
  VALIDATE_DATA: 'VALIDATE_DATA',
  TRIM_DATA: 'TRIM_DATA',
  GET_CITY: 'GET_CITY',
  RESET_DATA: 'RESET_DATA',
  SET_DATA: 'SET_DATA',
};

export const FORM_REQUIRED_FIELDS = [formNames.name, formNames.address, formNames.city, formNames.zipCode];

export const radioButtonDefaultValues = [
  {
    id: 1,
    value: null,
    label: 'Unknown',
  },
  {
    id: 2,
    value: true,
    label: 'Yes',
  },
  {
    id: 3,
    value: false,
    label: 'No',
  },
];

export const radioButtonsList = [
  formNames.chain,
  formNames.sellTobacco,
  formNames.sellAlcohol,
  formNames.hasPharmacy,
  formNames.hasAVC,
  formNames.acceptsWIC,
  formNames.acceptsSNAP,
  formNames.internetSales,
];

export const selectsList = [formNames.city, formNames.type, formNames.ageRestriction];

export const getInitialValue = (name) => {
  const findName = (arr, inputName) => arr.includes(inputName) && inputName;

  switch (name) {
    case findName(radioButtonsList, name): {
      return radioButtonDefaultValues[0].value;
    }
    case findName(selectsList, name): {
      return {
        id: 0,
        label: name === formNames.city ? '' : 'Select an option',
        value: null,
        name,
      };
    }
    case formNames.inBusiness:
      return radioButtonDefaultValues[1].value;
    case formNames.closeDate: {
      return {
        value: null,
        selected: '',
      };
    }
    case formNames.latlong: {
      return {
        latitude: '',
        longitude: '',
      };
    }
    default:
      return '';
  }
};

const defaultRetailerState = Object.values(formNames).reduce(
  (newObj, value) => ({ ...newObj, [value]: getInitialValue(value) }),
  {},
);

export const formInitialState = {
  data: { attributes: defaultRetailerState, type: 'Retailer' },
  errors: createInitialValues(formNames, null),
  canBeSubmitted: false,
};

export const textInputs = [
  {
    id: 1,
    name: formNames.corporation,
    title: 'Corporation',
  },
  {
    id: 2,
    name: formNames.license,
    title: 'License number',
  },
  {
    id: 3,
    name: formNames.other,
    title: 'Other',
  },
  {
    id: 4,
    name: formNames.other2,
    title: 'Other',
  },
];

export const radioButtonsGroup = [
  {
    title: 'Sells tobacco',
    name: formNames.sellTobacco,
    disabled: true,
  },
  { title: 'Sells cigarettes', name: formNames.sellCigarettes, isSubcategory: true },
  { title: 'Sells cigars', name: formNames.sellCigars, isSubcategory: true },
  { title: 'Sells e-cigarettes', name: formNames.sellEcigarettes, isSubcategory: true },
  { title: 'Sells smokeless tobacco products', name: formNames.sellSmokeless, isSubcategory: true },
  {
    title: 'Sells alcohol',
    name: formNames.sellAlcohol,
  },
  {
    title: 'Sells lottery tickets',
    name: formNames.ticketsSold,
  },
  {
    title: 'Has gaming machines',
    name: formNames.gamingMachine,
  },
  {
    title: 'Has pharmacy counter',
    name: formNames.hasPharmacy,
  },
  {
    title: 'Has AVC agreement',
    name: formNames.hasAVC,
  },
  {
    title: 'Accepts WIC',
    name: formNames.acceptsWIC,
  },
  {
    title: 'Accepts SNAP',
    name: formNames.acceptsSNAP,
  },
  {
    title: 'Internet Sales',
    name: formNames.internetSales,
  },
];

export const retailerTypeOptions = [
  {
    id: 1,
    value: 1,
    label: 'Convenience store (with or without gas)',
    key: 'cdph_business_type',
    name: formNames.type,
  },
  {
    id: 2,
    value: 2,
    label: 'Drug store/Pharmacy',
    key: 'cdph_business_type',
    name: formNames.type,
  },
  {
    id: 3,
    value: 3,
    label: 'Beer, wine, liquor store',
    key: 'cdph_business_type',
    name: formNames.type,
  },
  {
    id: 4,
    value: 4,
    label: 'Grocery store',
    key: 'cdph_business_type',
    name: formNames.type,
  },
  {
    id: 5,
    value: 5,
    label: 'Mass merchandiser',
    key: 'cdph_business_type',
    name: formNames.type,
  },
  {
    id: 6,
    value: 6,
    label: 'Tobacco shop',
    key: 'cdph_business_type',
    name: formNames.type,
  },
  {
    id: 7,
    value: 7,
    label: 'Hookah lounge',
    key: 'cdph_business_type',
    name: formNames.type,
  },
  {
    id: 8,
    value: 8,
    label: 'E-cig / Vape shop',
    key: 'cdph_business_type',
    name: formNames.type,
  },
  {
    id: 9,
    value: 9,
    label: 'Bar or restaurant',
    key: 'cdph_business_type',
    name: formNames.type,
  },
  {
    id: 10,
    value: 10,
    label: 'Smoking bar',
    key: 'cdph_business_type',
    name: formNames.type,
  },
  {
    id: 99,
    value: 99,
    label: 'Other not listed',
    key: 'cdph_business_type',
    name: formNames.type,
  },
];

export const ageRestrictedFacilityOptions = [
  {
    id: 1,
    value: 1,
    label: '18',
    name: formNames.ageRestriction,
  },
  {
    id: 2,
    value: 2,
    label: '21',
    name: formNames.ageRestriction,
  },
  {
    id: 3,
    value: 3,
    label: 'Other',
    name: formNames.ageRestriction,
  },
];

export const ageRestriction = ageRestrictedFacilityOptions.reduce((acc, item) => {
  acc[item.value] = item.label;
  return acc;
}, {});

export const SUBCATEGORIES = [
  { name: 'cigarettes_sold', category: 'tobacco' },
  { name: 'cigars_sold', category: 'tobacco' },
  { name: 'ecig_sold', category: 'tobacco' },
  { name: 'smokeless_sold', category: 'tobacco' },
];

export const PAGE_COLUMNS = 'three_columns';
