import { isEmpty } from 'lodash';
import { capitalizeFirstLetter } from 'utils/custom';
import moment from 'moment';
import FILTER_OPTIONS from '_constants/old/_constants/filters/FILTER_OPTIONS';

const getFormattedDate = (date) => (isEmpty(date) ? null : moment(date).format('MM/DD/YYYY'));

const getStoreType = (type) => {
  const storeType = FILTER_OPTIONS.STORE_TYPE_OPTIONS.find(({ value }) => value === type);
  if (!storeType) {
    if (type !== '') {
      console.log('Store type not found', type);
    }
    return null;
  }
  return isEmpty(type) ? null : storeType.label;
};

const getAddress = (address, address2) => {
  if (address2) {
    return `${address}, ${address2}`;
  }
  return address;
};

const getFilteredCampaignSet = (campaigns) => {
  return campaigns.data.map((item) => Number(item.id));
};

export default (data) => {
  const retailers = data.map((item) => {
    const { attributes, relationships } = item;
    const {
      name,
      address,
      address2,
      city,
      zipcode,
      county_name: countyName,
      phone,
      violation_status: violation,
      cdph_business_type: businessType,
      age_restricted_flag: ageRestrictedFlag,
      closed_date: closedDate,
      tobacco,
      pharmacy,
    } = attributes;

    const { aggregationpolygon_set: secGeogs, campaign_set: campaignSet } = relationships;
    const retailerItem = {
      id: item.id,
      name: capitalizeFirstLetter(name),
      address: getAddress(address, address2),
      city,
      zipcode,
      county_name: countyName,
      phone,
      violation,
      cdph_business_type: getStoreType(businessType),
      age_restricted_flag: ageRestrictedFlag,
      closed_date: getFormattedDate(closedDate),
      tobacco,
      pharmacy,
      sec_geogs: secGeogs,
      campaignSet: getFilteredCampaignSet(campaignSet),
    };
    return retailerItem;
  });

  return retailers;
};
