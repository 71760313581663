/* eslint-disable no-prototype-builtins */
import React from 'react';
import { FILTER_FIELDS, FILTER_OPTIONS } from '_constants/old/filters';
import { CheckedList } from 'modules/Modals/old/FilterModal/components/CheckboxGroup';
import { CollapsedTab } from 'modules/Modals/old/FilterModal/components/CollapsedTab';
import PropTypes from 'prop-types';
import * as Styled from './styles';

function RetailerCharacteristics({ isVaAccount }) {
  const RETAILER_CHARACTERISTICS_TABS = [
    {
      type: 'multiple',
      title: 'Products sold',
      id: 1,
      collapsed: [
        {
          id: 2,
          title: 'Tobacco',
          field: FILTER_FIELDS.TOBACCO,
          options: FILTER_OPTIONS.SELLS_TOBACCO_OPTIONS,
        },
        {
          id: 3,
          title: 'Sells cigarettes',
          field: FILTER_FIELDS.SELL_CIGARETTES,
          options: FILTER_OPTIONS.SELLS_CIGARETTES_OPTIONS,
          isSubcategory: true,
        },
        {
          id: 4,
          title: 'Sells cigars',
          field: FILTER_FIELDS.SELL_CIGARS,
          options: FILTER_OPTIONS.SELLS_CIGARS_OPTIONS,
          isSubcategory: true,
        },
        {
          id: 5,
          title: 'Sells e-cigarettes',
          field: FILTER_FIELDS.SELL_ECIG,
          options: FILTER_OPTIONS.SELLS_ECIG_OPTIONS,
          isSubcategory: true,
        },
        {
          id: 6,
          title: 'Sells smokeless tobacco products',
          field: FILTER_FIELDS.SELL_SMOKELESS,
          options: FILTER_OPTIONS.SELLS_SMOKELESS_OPTIONS,
          isSubcategory: true,
        },
        {
          id: 7,
          title: 'Alcohol',
          field: FILTER_FIELDS.ALCOHOL,
          options: FILTER_OPTIONS.SELLS_ALCOHOL_OPTIONS,
        },
      ],
    },
    isVaAccount && {
      type: 'multiple',
      title: 'Gambling',
      id: 8,
      collapsed: [
        {
          id: 9,
          title: 'Sells lottery tickets',
          field: FILTER_FIELDS.SELLS_TICKETS,
          options: FILTER_OPTIONS.SELLS_TICKETS_OPTIONS,
        },
        {
          id: 10,
          title: 'Has gaming machines',
          field: FILTER_FIELDS.HAS_GAMING_MACHINES,
          options: FILTER_OPTIONS.HAS_GAMING_MACHINES_OPTIONS,
        },
      ],
    },
    {
      id: 11,
      field: FILTER_FIELDS.RETAILER_BUSINESS_TYPE,
      title: 'Store type',
      options: FILTER_OPTIONS.STORE_TYPE_OPTIONS,
      type: 'single',
    },
    {
      type: 'multiple',
      title: 'Proximity',
      id: 12,
      collapsed: [
        {
          id: 13,
          field: FILTER_FIELDS.NEAR_SCHOOL,
          title: 'Near schools',
          options: FILTER_OPTIONS.NEAR_SCHOOL_OPTIONS,
        },
        {
          id: 14,
          field: FILTER_FIELDS.NEAR_RETAILER,
          title: 'Near retailers',
          options: FILTER_OPTIONS.NEAR_RETAILER_OPTIONS,
        },
      ],
    },
    {
      type: 'double',
      title: 'Other retailer details',
      id: 15,
      collapsed: [
        {
          id: 16,
          field: FILTER_FIELDS.WIC,
          options: FILTER_OPTIONS.WIC_OPTIONS,
        },
        {
          id: 17,
          field: FILTER_FIELDS.SNAP,
          options: FILTER_OPTIONS.SNAP_OPTIONS,
        },
        {
          id: 18,
          field: FILTER_FIELDS.PHARMACY,
          options: FILTER_OPTIONS.HAS_PHARMACY_OPTIONS,
        },
        {
          id: 19,
          field: FILTER_FIELDS.HAS_AVC_AGREEMENT,
          options: FILTER_OPTIONS.HAS_AVC_AGREEMENT_OPTIONS,
        },
        {
          id: 20,
          field: FILTER_FIELDS.INTERNET_SALES_VISTIS,
          options: FILTER_OPTIONS.INTERNET_SALES_OPTIONS,
        },
      ],
    },
  ];

  const getElement = (item) => {
    switch (item.type) {
      case 'single':
        return (
          <CollapsedTab title={item.title} key={item.id}>
            <CheckedList name={item.field} options={item.options} />
          </CollapsedTab>
        );
      case 'multiple':
        return (
          <CollapsedTab title={item.title} key={item.id}>
            {item.collapsed.map((elem) => (
              <Styled.FiltersContainer>
                <Styled.FiltersTitle isSubcategory={elem?.isSubcategory}>{elem.title}</Styled.FiltersTitle>
                <CheckedList name={elem.field} options={elem.options} isSubcategory={elem?.isSubcategory} />
              </Styled.FiltersContainer>
            ))}
          </CollapsedTab>
        );
      case 'double':
        return (
          <CollapsedTab title={item.title} key={item.id}>
            {item.collapsed.map((elem) => (
              <CheckedList name={elem.field} options={elem.options} />
            ))}
          </CollapsedTab>
        );
      default:
        return null;
    }
  };

  return <Styled.Wrapper>{RETAILER_CHARACTERISTICS_TABS.map((item) => getElement(item))}</Styled.Wrapper>;
}

RetailerCharacteristics.propTypes = {
  isVaAccount: PropTypes.bool,
};

RetailerCharacteristics.defaultProps = {
  isVaAccount: false,
};

export default RetailerCharacteristics;
