import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { BaseTable, BaseMobileTable } from 'ui-kit';
import useWindowDimensions from '../../hooks/useWindowDimensions';

function TableWrapper({
  columns,
  data,
  isEdit,
  tableControlParams,
  editCellParams,
  archiveCellParams,
  getTrPropsFunctions,
  isManageAssignmentsTable,
}) {
  const { width: viewportWidth } = useWindowDimensions();

  return (
    <>
      {viewportWidth >= 900 ? (
        <BaseTable isEdit={isEdit} columns={columns} data={data} getTrPropsFunctions={getTrPropsFunctions} />
      ) : (
        <BaseMobileTable
          isEdit={isEdit}
          columns={columns}
          data={data}
          tableControlParams={tableControlParams}
          editCellParams={editCellParams}
          archiveCellParams={archiveCellParams}
          isManageAssignmentsTable={isManageAssignmentsTable}
        />
      )}
    </>
  );
}

TableWrapper.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  editCellParams: PropTypes.object.isRequired,
  tableControlParams: PropTypes.object.isRequired,
  isEdit: PropTypes.bool,
  archiveCellParams: PropTypes.object,
  getTrPropsFunctions: PropTypes.object,
  isManageAssignmentsTable: PropTypes.bool,
};

TableWrapper.defaultProps = {
  isEdit: false,
  archiveCellParams: {},
  getTrPropsFunctions: {},
  isManageAssignmentsTable: false,
};

export default memo(TableWrapper);
