/* eslint-disable react/button-has-type */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react-hooks/exhaustive-deps */
import qs from 'qs';

// export const SetQueryParam = ({ title, value }) => {
//   const [foo, setParam] = useQueryParam(title, NumberParam);
//   useEffect(() => {
//     setParam(value);
//   }, [value]);

//   return null;
// };

// export const SetQueryParams = ({ props }) => {
//   const [foo, setParam] = useQueryParams(props);
//   setParam(undefined, 'push');
// };

export const queryParams = (paramObj, search, history, isSoloFilters) => {
  const searchObj = qs.parse(search.split('?')[1]);
  const queryObj = isSoloFilters
    ? { ordering: searchObj.ordering, page_size: searchObj.page_size, ...paramObj }
    : { ...searchObj, ...paramObj };
  const queryString = Object.keys(queryObj)
    .map((key) => `${key}=${queryObj[key]}`)
    .join('&');
  history.push({
    pathname: '/retailers', // why we have hardcoded retailers here?
    search: queryString,
  });
};

export const SetQueryParams = ({ queryString, history }) => {
  history.replace({
    pathname: history.location.pathname,
    search: qs.stringify(queryString, { skipNulls: true, arrayFormat: 'repeat', indices: false }),
  });
};

export const removeQueryParams = (query, search, history) => {
  const searchObj = qs.parse(search.split('?')[1]);
  Object.keys(query).map((key) => delete searchObj[key]);
  const queryString = Object.keys(searchObj)
    .map((key) => `${key}=${searchObj[key]}`)
    .join('&');
  history.push({
    pathname: '/retailers',
    search: queryString,
  });
};
