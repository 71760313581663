import { REQUEST_HEADERS, API_URLS } from '_constants';
import * as TYPES from './Retailer.types';

export const fetchCityList = (str) => ({
  types: [TYPES.GET_RETAILER_CITY, TYPES.GET_RETAILER_CITY_SUCCESS, TYPES.GET_RETAILER_CITY_FAIL],
  promise: (client) => client.get(`${API_URLS.cities}?name=${str}`),
});

export const addRetailer = (data) => ({
  types: [TYPES.ADD_RETAILER, TYPES.ADD_RETAILER_SUCCESS, TYPES.ADD_RETAILER_FAIL],
  promise: (client) => client.post(API_URLS.retailers, data, { headers: { ...REQUEST_HEADERS } }),
});

export const getRetailerInfo = (retailerId, isSurvey) => ({
  types: [TYPES.GET_RETAILER, TYPES.GET_RETAILER_SUCCESS, TYPES.GET_RETAILER_FAIL],
  promise: (client) => client.get(`${API_URLS.retailers}/${retailerId}${isSurvey ? '' : '?include=visits'}`),
});

export const editRetailer = (id, data) => ({
  types: [TYPES.EDIT_RETAILER, TYPES.EDIT_RETAILER_SUCCESS, TYPES.EDIT_RETAILER_FAIL],
  promise: (client) => client.patch(`${API_URLS.retailers}/${id}`, data, { headers: { ...REQUEST_HEADERS } }),
});

export const checkNewRetailerLocation = (params) => ({
  types: [TYPES.CHECK_RETAILER_LOCATION, TYPES.CHECK_RETAILER_LOCATION_SUCCESS, TYPES.CHECK_RETAILER_LOCATION_FAIL],
  promise: (client) => client.get(`${API_URLS.mapping}?${params}`),
});

export const resetRetailerData = () => ({
  type: TYPES.RESET_RETAILER_DATA,
});
