import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TabOrganizer } from 'ui-kit';
import * as Styled from './styles';

/**
 * @example
 * {
 *    title: 'Displayed title'
 *    td: 'tab id'
 *    component: <Component />
 * }
 */

function Tabs({ amount, tabOptions, activeTab, onChangeTabs, isVisit, isMainTab }) {
  const [activeLocalTab, setActiveLocalTab] = useState(activeTab || tabOptions[0]);
  const tabTitles = tabOptions.map((item) => item.title);

  const changeTabsHandler = (titleTab) => {
    const selectedTab = tabOptions.find((item) => item.title === titleTab);
    setActiveLocalTab(selectedTab);
    if (onChangeTabs !== null) {
      onChangeTabs(selectedTab);
    }
  };

  // For amount props
  useEffect(() => {
    if (onChangeTabs) {
      onChangeTabs(activeTab || tabOptions[0]);
    }
  }, []);

  return (
    <Styled.Wrapper>
      <Styled.TabsPanelWrapper isVisit={isVisit} isMainTab={isMainTab} className="table-info">
        <TabOrganizer
          tabsTitle={tabTitles}
          selectedValue={activeLocalTab.title}
          useActiveTab={changeTabsHandler}
          borderLeft
          isBorder
          minWidth="162px"
          minHeight="40px"
          isMap="162px"
          isMargin="0 0"
          borderColor="#E0E0E0;"
          inputId="mapInputId"
        />
        {activeLocalTab?.title === 'Visits' && <Styled.AmountText>{`${amount} items`}</Styled.AmountText>}
      </Styled.TabsPanelWrapper>
      {tabOptions.map(({ id, component }) => (
        <Styled.ComponentWrapper className={id === activeLocalTab.id ? 'active' : ''} key={id}>
          {component}
        </Styled.ComponentWrapper>
      ))}
    </Styled.Wrapper>
  );
}

Tabs.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  tabOptions: PropTypes.array.isRequired,
  activeTab: PropTypes.object,
  onChangeTabs: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
  isVisit: PropTypes.bool,
  isMainTab: PropTypes.bool,
};

Tabs.defaultProps = {
  onChangeTabs: null,
  activeTab: null,
  amount: null,
  isVisit: false,
  isMainTab: false,
};

export default Tabs;
