import styled from 'styled-components';

export const HeaderContainer = styled.div`
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BodyContainer = styled.main``;

export const FooterContainer = styled.div`
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Wrapper = styled.div`
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
`;
