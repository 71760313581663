import React from 'react';
import Footer from 'modules/Footer';
import Header from 'modules/Header';

import * as Styled from './styles';

function PageContainer({ children }) {
  return (
    <Styled.Wrapper id="html-canvas">
      <Header />
      <Styled.BodyContainer>{children}</Styled.BodyContainer>
      <Footer />
    </Styled.Wrapper>
  );
}

export default PageContainer;
