import styled, { css } from 'styled-components';
import { Link as DefaultLink } from 'react-router-dom';
import { DEVICES } from '_constants';

export const HeaderContainer = styled.header`
  position: sticky;
  top: 0;
  z-index: 500; // to overlap map on edit retailer page
  height: 54px;
  background-color: var(--lite-grey);
  display: flex;
  align-items: center;
  padding: 0 27px;

  @media ${DEVICES.header} {
    padding: 0 16px;
  }
`;

export const LinkElem = styled(DefaultLink)`
  text-decoration: none;
  color: var(--dark);
  font-size: 14px;
  line-height: 16px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-transform: uppercase;

  &:focus {
    box-shadow: 0 0 0 2px var(--focus);
  }

  @media print {
    display: none !important;
  }
  @media ${DEVICES.header} {
    height: auto;
    justify-content: flex-start;
  }
  ${({ isLoginLink }) =>
    isLoginLink &&
    css`
      border-bottom: 2px solid var(--danger);
      height: calc(100% - 4px);
      margin-top: 2px;
      margin-right: 30px;
      @media ${DEVICES.tablet} {
        display: ${({ type }) => (type === 'logo' ? 'flex' : 'none')};
        margin: 0 16px 0 0;
      }
    `}
`;

export const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    cursor: pointer;
  }
`;

export const Counter = styled.div`
  position: absolute;
  bottom: 45%;
  left: 45%;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: var(--danger);
  color: white;
  text-align: center;
`;
