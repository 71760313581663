import React from 'react';
import { URLS } from '_constants';
import { VisitView } from 'modules';

function EducationView() {
  return (
    <VisitView
      headerTitle="View education visit"
      sectionTitle="Education information"
      editPageUrl={URLS.educationPages.edit}
      editViolationUrl={URLS.educationPages.violationsEdit}
    />
  );
}

export default EducationView;
