import React from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { ActionCell, Checkbox } from 'ui-kit';
import * as Styled from './styles';

const getFiled = (accessor, field) =>
  accessor
    .split('.')
    .filter((item) => item !== 'editPen' && item !== 'archive')
    .reduce((obj, prop) => obj[prop], field);

function BaseMobileTable({ columns, data, isEdit, editCellParams, archiveCellParams, isManageAssignmentsTable }) {
  if (isEmpty(data)) return null;
  const { showArchived, handleCheckbox, checkboxList } = archiveCellParams;

  const tableData = data.map((item) => {
    const newItem = columns.reduce((acc, accItem) => {
      if (isEdit && accItem.accessor === 'editPen') {
        return [
          {
            body: <ActionCell original={item} {...editCellParams} />,
          },
          ...acc,
        ];
      }
      if (accItem.accessor === 'archive') {
        const { id } = item?.campaign;
        if (showArchived === null) return acc;

        return [
          {
            body: (
              <Checkbox
                label={showArchived ? 'Archive' : 'Unarchive'}
                onChange={handleCheckbox}
                name={id}
                value={checkboxList[id] || false}
              />
            ),
          },
          ...acc,
        ];
      }

      const field = getFiled(accItem?.accessor, item);
      const newRow = {
        header: <accItem.Header />,
        body: <accItem.Cell value={field} original={item} />,
      };
      return acc.concat(newRow);
    }, []);
    return newItem;
  });

  const revisitsNeeded = data.reduce((acc, element, index) => {
    if (element.revisitNeeded) {
      acc.push(index);
    }
    return acc;
  }, []);

  return (
    <Styled.TableWrapper>
      <Styled.TableList>
        {tableData.map((tableItem, index) => (
          <Styled.TableItem
            className="table-li"
            revisitNeeded={isManageAssignmentsTable && revisitsNeeded.includes(index)}
          >
            {tableItem.map((tableRow) => (
              <Styled.TableRow>
                {tableRow.header && <Styled.TableHeader>{tableRow.header}</Styled.TableHeader>}
                <Styled.TableBody>{tableRow.body}</Styled.TableBody>
              </Styled.TableRow>
            ))}
          </Styled.TableItem>
        ))}
      </Styled.TableList>
    </Styled.TableWrapper>
  );
}

BaseMobileTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultPageSize: PropTypes.number,
  loading: PropTypes.bool,
  isEdit: PropTypes.bool,
  tableControlParams: PropTypes.func.isRequired,
  editCellParams: PropTypes.func.isRequired,
  archiveCellParams: PropTypes.object,
  isManageAssignmentsTable: PropTypes.bool,
};

BaseMobileTable.defaultProps = {
  defaultPageSize: 20,
  loading: false,
  isEdit: false,
  archiveCellParams: {},
  isManageAssignmentsTable: false,
};

export default BaseMobileTable;
